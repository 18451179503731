import { z, ZodType } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import { KaeplaDomainType } from './KaeplaDomainType.js';

export type KaeplaFirestoreDomainListPath = '/domains';
export type KaeplaFirestoreDomainPath = `${KaeplaFirestoreDomainListPath}/${string}`;

export interface KaeplaDomain {
  domain: string;
  domainType: KaeplaDomainType;
  id: string;
  resellerId?: string;
  customerId?: string;
  updatedAt: FirestoreTimestamp;
  accessCount: number;
}

export const kaeplaDomainSchema = z.object({
  domain: z.string(),
  domainType: z.nativeEnum(KaeplaDomainType),
  id: z.string(),
  resellerId: z.string().optional(),
  customerId: z.string().optional(),
  updatedAt: firestoreTimestampSchema,
  accessCount: z.number(),
}) satisfies ZodType<KaeplaDomain>;
