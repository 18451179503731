import { Box, Paper, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import packageJson from '../../../../package.json';
import { useBrandingQuery } from '../../../hooks/query/useBrandingQuery.js';
import { IS_CI } from '../../../mode.js';
import { SWUpdater } from '../../features/ServiceWorkerUpdater.js';
import { isBeta } from '../../helpers/isBeta';

import { BrandedLogin } from './features/BrandedLogin.js';
import { ConnectBrandedLogin } from './features/ConnectBrandedLogin.js';
import { ConnectFailed } from './features/ConnectFailed.js';
import { CustomBrandedLogin } from './features/CustomBrandedLogin.js';

const versionAddendum = IS_CI ? ' CI' : '';

export function LoginLayout(properties: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#EEEEEE',
      }}
    >
      <SWUpdater />
      <Paper id="login-box" sx={{ padding: 2, minWidth: 350, maxWidth: 400 }}>
        <Box data-testid="login-BrandedLoginPaper" sx={{ padding: 3 }}>
          {properties.children}
        </Box>
      </Paper>
      <Typography
        data-testid="login-version"
        component="div"
        variant="caption"
        sx={{ textAlign: 'center', pt: 2 }}
      >
        {isBeta() && (
          <Box component="span" sx={{ color: red[500], fontSize: 14, mr: 1 }}>
            β
          </Box>
        )}
        {packageJson.version}
        {versionAddendum}
      </Typography>
    </Box>
  );
}

export function Login() {
  const query = useBrandingQuery();

  if (query.isLoading) {
    return (
      <LoginLayout>
        <ConnectBrandedLogin />
      </LoginLayout>
    );
  }

  if (query.isError || !query.data?.appName) {
    //
    // this should be handled by the error boundary
    //
    return (
      <LoginLayout>
        <ConnectFailed />
      </LoginLayout>
    );
  }

  if (!query.data) {
    //
    // can occur if the hostname is not found
    //
    return (
      <LoginLayout>
        <ConnectFailed />
      </LoginLayout>
    );
  }

  if (!query.data.authenticationConfiguration) {
    return (
      <LoginLayout>
        <BrandedLogin branding={query.data} />
      </LoginLayout>
    );
  }

  if (query.data.authenticationConfiguration) {
    return (
      <LoginLayout>
        <CustomBrandedLogin branding={query.data} />
      </LoginLayout>
    );
  }

  throw new Error('Could not initialize login screen');
}
