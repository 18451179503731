import { z } from 'zod';

import { KaeplaOperation, kaeplaOperationSchema } from './Application/KaeplaOperation.js';
import { MatrixJob, matrixJobSchema } from './MatrixJob.js';

export interface MatrixOperations {
  rows: KaeplaOperation[];
  job: MatrixJob;
}

export const matrixOperationsSchema = z.object({
  rows: kaeplaOperationSchema.array(),
  job: matrixJobSchema,
}) satisfies z.ZodType<MatrixOperations>;
