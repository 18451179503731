import { z } from 'zod';

import { KaeplaDimension, dimensionSchema } from './KaeplaDimension.js';
import { KaeplaDimensionDetail, kaeplaDimensionDetailSchema } from './KaeplaDimensionDetail.js';

export interface KaeplaDimensionDetails extends KaeplaDimension {
  dimensionDetails: KaeplaDimensionDetail[];
  columnName: string;
  scopePath: string[];
  scopePathStringified: string;
  projectId: string;
}

export const kaeplaDimensionDetailsSchema = z
  .object({
    dimensionDetails: kaeplaDimensionDetailSchema.array(),
    columnName: z.string(),
    scopePath: z.string().array(),
    scopePathStringified: z.string(),
    projectId: z.string(),
  })
  .and(dimensionSchema) satisfies z.ZodType<KaeplaDimensionDetails>;
