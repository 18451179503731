import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import { KaeplaOperationType } from './KaeplaOperationType.js';

export interface KaeplaOperation {
  importSource?: string;
  isValid?: boolean;
  invalidCalculationsCount?: number;
  lastValidationCheck: FirestoreTimestamp;
  operation?: string;
  precalculatedField?: string;
  resultField: string;
  type: KaeplaOperationType;
}

export const kaeplaOperationSchema = z.object({
  importSource: z.string().optional(),
  isValid: z.boolean().optional(),
  invalidCalculationsCount: z.number().optional(),
  lastValidationCheck: firestoreTimestampSchema,
  operation: z.string().optional(),
  precalculatedField: z.string().optional(),
  resultField: z.string(),
  type: z.nativeEnum(KaeplaOperationType),
}) satisfies z.ZodType<KaeplaOperation>;
