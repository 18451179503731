import { queryOptions } from '@tanstack/react-query';

import { ResellerFindManyParameter, ResellerRepository } from '../repository/Reseller.repository';

export class ResellerService {
  repo = new ResellerRepository();

  find(id: string) {
    return queryOptions({
      queryKey: [this.repo.path, id],
      queryFn: () => this.repo.find(id),
    });
  }

  findMany(parameter?: ResellerFindManyParameter) {
    return queryOptions({
      queryKey: [this.repo.path, parameter],
      queryFn: () => this.repo.findMany(parameter),
    });
  }
}
