import { FirestoreWatcherHandler } from '@atrigam/atrigam-service-firebase-watcher';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { kaeplaFirebaseConfig } from '../kaeplaFirebase.config.js';

import { logger } from './Frontend/helpers/logger.js';
import {
  IS_CI,
  IS_DEV,
  IS_EMULATOR,
  SHOULD_EMULATE_FIRESTORE,
  SHOULD_EMULATE_FUNCTIONS,
} from './mode.js';

function setup() {
  // Initialize Firebase
  const firebaseConfig = kaeplaFirebaseConfig();
  const app = initializeApp(firebaseConfig, 'kaepla');
  const store = initializeFirestore(app, { experimentalForceLongPolling: true });

  // initialize watcher
  /*
  allowedDuplicatePaths: ['projectAssignments', 'resellerAssignments', 'customerAssignments']

  because we have 
  1) a listener for each per logged in user and
  2) for each project, reseller and customer team

  */
  const service = FirestoreWatcherHandler();
  service.initialize({
    app,
    allowedDuplicatePaths: [
      'projectAssignments',
      'resellerAssignments',
      'customerAssignments',
      'importLogs',
      'aiThreads',
      'aiRuns',
      'aiMessages',
      'aiToolCalls',
    ],
    isDev: IS_DEV,
    logError: ({ error, context }) => {
      // TODO: skip if we are logging out
      logger.log(error, context);
    },
  });

  return {
    kaeplaAuth: getAuth(app),
    firebaseApp: app,
    store,
    watcherServiceKaepla: service,
  };
}

export const { watcherServiceKaepla, firebaseApp, kaeplaAuth } = setup();

if (IS_CI || IS_EMULATOR) {
  logger.log('CI or EMULATOR environment detected!');
  // on a local emulator and the ci environment
  const localHosts = [
    'localhost',
    '127.0.0.1',
    '10.0.0.18',
    'customer-simulations.atrigam.local',
    'customer-planning.atrigam.local',
    'simulations.atrigam.local',
    'simulations.xotigo.local',
    'alpha-sandbox.xotigo.local',
  ];

  if (localHosts.includes(globalThis.location.hostname)) {
    const firestoreDatabase = getFirestore(firebaseApp);
    const functions = getFunctions(firebaseApp, 'europe-west3');
    connectAuthEmulator(kaeplaAuth, 'http://localhost:9099');
    connectFirestoreEmulator(firestoreDatabase, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
  } else {
    logger.warn('Localhost not detected, but CI or EMULATOR environment detected!');
  }
}

if (SHOULD_EMULATE_FIRESTORE) {
  logger.log('Emulate Firestore!');
  const firestoreDatabase = getFirestore(firebaseApp);
  connectFirestoreEmulator(firestoreDatabase, 'localhost', 8080);
}

if (SHOULD_EMULATE_FUNCTIONS) {
  logger.log('Emulate Functions!');
  const functions = getFunctions(firebaseApp, 'europe-west3');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
