import {
  toggleAppLoadingLog,
  toggleLayoutLog,
  toggleMfaLog,
  toggleParseErrorLog,
  toggleRequestLog,
  toggleToDoLog,
} from './Frontend/helpers/logger';

toggleAppLoadingLog(false);
toggleLayoutLog(false);
toggleRequestLog(false);
toggleMfaLog(false);
// we want to see them always
toggleParseErrorLog(false);
toggleToDoLog(false);
