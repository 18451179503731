import { Timestamp } from 'firebase/firestore';
import { z, ZodType } from 'zod';

import { firestoreTimestampSchema } from '../FirestoreTimeStamp';

import { KaeplaFirestoreCustomerPath } from './KaeplaCustomer';

export type KaeplaFirestoreConnectorListPath = `${KaeplaFirestoreCustomerPath}/connectors`;
export type KaeplaFirestoreConnectorPath = `${KaeplaFirestoreConnectorListPath}/${string}`;

export type KaeplaConnectorType = 'salesForce' | 'excel365' | 'googleSheets';

export interface KaeplaConnector {
  id: string;
  name: string;
  description: string;
  customerId: string;
  connectorTypeId: KaeplaConnectorType;
  credentialsAreValid?: boolean;
  createdBy: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy: string;
  error?: KaeplaConnectorError;
}

export interface KaeplaConnectorError {
  code: KaeplaConnectionErrorType;
  message: string;
  stopConnection: boolean;
}

export type KaeplaConnectionErrorType = 'passwordExpired';

export const kaeplaConnectionErrorSchema = z.object({
  code: z.literal('passwordExpired'),
  message: z.string(),
  stopConnection: z.boolean(),
}) satisfies ZodType<KaeplaConnectorError>;

export const kaeplaConnectorSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  customerId: z.string(),
  connectorTypeId: z.union([
    z.literal('salesForce'),
    z.literal('excel365'),
    z.literal('googleSheets'),
  ]),
  credentialsAreValid: z.boolean().optional(),
  createdBy: z.string(),
  createdAt: firestoreTimestampSchema,
  updatedAt: firestoreTimestampSchema,
  updatedBy: z.string(),
  error: kaeplaConnectionErrorSchema.optional(),
}) satisfies ZodType<KaeplaConnector>;
