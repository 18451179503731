import { z, ZodType } from 'zod';

import { DataType } from '../DataTypes';
import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp';

import { KaeplaFirestoreProjectPath } from './KaeplaProject';

export type KaeplaDimensionListPath = `${KaeplaFirestoreProjectPath}/dimensions`;
export type KaeplaDimensionPath = `${KaeplaDimensionListPath}/${string}`;

export interface KaeplaDimension {
  id: string;
  columnName: string;
  createdAt: FirestoreTimestamp; // timestamp when the dimension was created
  updatedAt: FirestoreTimestamp; // timestamp when the dimension was last updated
  label?: string; // label got from source system
  description?: string; // description got from source system
  archived?: boolean; // flag to mark a dimension as not in use
  archivedAt?: FirestoreTimestamp; // timestamp when the dimension was archived
  userModified?: boolean; // suggestion: rename to "protected"
  userModifiedAt?: FirestoreTimestamp; // timestamp when the dimension was last modified by user

  currency?: string; // field containing the currency-code for that dimension
  dataType: DataType;
  decimalPositions?: number;

  // user set attributes
  labelOverride?: string; // overrides source system label
  descriptionOverride?: string; // overrides source system description
  enableAggregation?: boolean;
  enablePivoting?: boolean;
  enableScope?: boolean;
  enableSimulation?: boolean;
  enableSlicing?: boolean;
  visible?: boolean;

  isAliasFor?: string;
  isCalculated?: boolean;
  isCurrency?: boolean;
  mapped?: string;
  ordinalPosition: number;
}

export const dimensionSchema = z.object({
  columnName: z.string(),
  archived: z.boolean().optional(),
  archivedAt: firestoreTimestampSchema.optional(),
  createdAt: firestoreTimestampSchema,
  currency: z.string().optional(),
  dataType: z.nativeEnum(DataType),
  decimalPositions: z.number().optional(),
  description: z.string().optional(),
  descriptionOverride: z.string().optional(),
  enableAggregation: z.boolean().optional(),
  enablePivoting: z.boolean().optional(),
  enableScope: z.boolean().optional(),
  enableSimulation: z.boolean().optional(),
  enableSlicing: z.boolean().optional(),
  id: z.string(),
  isAliasFor: z.string().optional(),
  isCalculated: z.boolean().optional(),
  isCurrency: z.boolean().optional(),
  label: z.string().optional(),
  labelOverride: z.string().optional(), // todo: maybe remove again?
  mapped: z.string().optional(),
  ordinalPosition: z.number(),
  updatedAt: firestoreTimestampSchema,
  userModified: z.boolean().optional(),
  userModifiedAt: firestoreTimestampSchema.optional(),
  visible: z.boolean().optional(),
}) satisfies ZodType<KaeplaDimension>;
