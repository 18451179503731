import { KaeplaBranding } from '@kaepla/types';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Divider, Grid2 as Grid, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import { useAuth } from '../../../../AuthReactProvider.js';
import { AuthProviderIcon } from '../../User/features/AuthProviders/AuthProviderIcon.js';
import { oAuth2Providers } from '../../User/features/AuthProviders/oAuth2Providers.js';

import { BrandedLoginHeader } from './BrandedLoginHeader.js';
import { EmailLoginOptions } from './EmailLoginOptions.js';

export function BrandedLogin({ branding }: { branding: KaeplaBranding | null }) {
  const { authError, authProcedureError, clearAuthError, clearAuthProcedureError } = useAuth();
  const navigate = useNavigate();

  return (
    <Grid container spacing={3} data-testid="login-BrandedLogin" alignItems="center">
      <Grid size={12}>
        <BrandedLoginHeader branding={branding} />
      </Grid>
      {authError && (
        <Grid size={12}>
          <Alert
            variant="outlined"
            severity="error"
            data-testid="login-BrandedLoginErrorAlert"
            action={
              <IconButton
                aria-label="close"
                data-testid="login-BrandedLoginErrorAlertClose"
                color="inherit"
                size="small"
                onClick={() => {
                  clearAuthError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {authError.code}
          </Alert>
        </Grid>
      )}
      {authProcedureError && (
        <Grid size={12}>
          <Alert
            variant="outlined"
            severity="error"
            data-testid="login-BrandedLoginErrorAlert"
            action={
              <IconButton
                aria-label="close"
                data-testid="login-BrandedLoginErrorAlertClose"
                color="inherit"
                size="small"
                onClick={() => {
                  clearAuthProcedureError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {authProcedureError}
          </Alert>
        </Grid>
      )}
      <Grid size={12}>
        <EmailLoginOptions branding={branding} />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        <Stack direction="row" justifyContent="center" data-testid="login-AuthProviderOptions">
          {oAuth2Providers
            // .filter((p) => p.active === true)
            .map((provider) => {
              return (
                <Box
                  data-testid={`login-OAuthProvider-${provider.providerId}`}
                  sx={{ m: 0.5 }}
                  key={provider.providerId}
                >
                  <AuthProviderIcon providerId={provider.providerId} />
                </Box>
              );
            })}
        </Stack>
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            data-testid="login-LoginOptions"
            onClick={() => {
              void navigate(`/LoginOptions`);
            }}
          >
            login options
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
