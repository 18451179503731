import { z } from 'zod';

import {
  KaeplaSimulationPercentage,
  kaeplaSimulationPercentageSchema,
} from './KaeplaSimulationPercentage';
import {
  KaeplaSimulationTextValue,
  kaeplaSimulationTextValueSchema,
} from './KaeplaSimulationTextValue';

// Base
export interface KaeplaSimulationParameterBase {
  dimension: string;
  rulesetId: string;
  label?: string;
}

export const kaeplaSimulationParameterBaseSchema = z.object({
  dimension: z.string(),
  rulesetId: z.string(),
  label: z.string().optional(),
}) satisfies z.ZodType<KaeplaSimulationParameterBase>;

// KaeplaSimulationParameterNumeric

export interface KaeplaSimulationParameterNumeric extends KaeplaSimulationParameterBase {
  aggregation: string;
  parameterType: 'numeric';
  percentages?: KaeplaSimulationPercentage[];
}

export const kaeplaSimulationParameterNumericSchema = kaeplaSimulationParameterBaseSchema.merge(
  z.object({
    aggregation: z.string(),
    parameterType: z.literal('numeric'),
    percentages: z.array(kaeplaSimulationPercentageSchema).optional(),
  }),
) satisfies z.ZodType<KaeplaSimulationParameterNumeric>;

// KaeplaSimulationParameterText

export interface KaeplaSimulationParameterText extends KaeplaSimulationParameterBase {
  parameterType: 'text';
  values?: KaeplaSimulationTextValue[];
}

export const kaeplaSimulationParameterTextSchema = kaeplaSimulationParameterBaseSchema.merge(
  z.object({
    parameterType: z.literal('text'),
    values: z.array(kaeplaSimulationTextValueSchema).optional(),
  }),
) satisfies z.ZodType<KaeplaSimulationParameterText>;

//

export type KaeplaSimulationParameter =
  | KaeplaSimulationParameterNumeric
  | KaeplaSimulationParameterText;

export const kaeplaSimulationParameterSchema = z.union([
  kaeplaSimulationParameterNumericSchema,
  kaeplaSimulationParameterTextSchema,
]) satisfies z.ZodType<KaeplaSimulationParameter>;
