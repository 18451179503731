import { queryOptions } from '@tanstack/react-query';

import { MatrixRepository } from '../repository/Matrix.repository';

export class MatrixService {
  repo = new MatrixRepository();

  findMeta(projectId: string) {
    return queryOptions({
      queryKey: ['MatrixService', 'getMeta', projectId],
      queryFn: () => this.repo.findMeta(projectId),
    });
  }

  findManyMeta(idList: string[]) {
    return queryOptions({
      queryKey: ['MatrixService', 'getMetaList', idList.sort()],
      queryFn: () => this.repo.findManyMeta(idList),
    });
  }
}
