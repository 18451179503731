/* eslint-disable @typescript-eslint/no-explicit-any */

let prefix: string = '';

export function setEnsurePrefix(value: string) {
  prefix = value;
}

/**
 *
 * 💥 `ensure` will `throw` an `Error` if the `condition` is [falsey]
 *
 * @example
 *
 * ```ts
 * const value: Person | null = { name: 'Alex' };
 * ensure(value, 'Expected value to be a person');
 * // type of `value`` has been narrowed to `Person`
 * ```
 */
export function ensure(
  condition: any,
  // Not providing an inline default argument for message as the result is smaller
  /**
   * Can provide a string, or a function that returns a string for cases where
   * the message takes a fair amount of effort to compute
   */
  message?: string | (() => string),
  cause?: string,
): asserts condition {
  if (condition) {
    return;
  }
  // Condition not passed

  // *This block will be removed in production builds*

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided}` : prefix;
  throw new Error(value, {
    cause,
  });
}
