import { KaeplaDomainType } from '@kaepla/types';
import { queryOptions } from '@tanstack/react-query';
import { isEmpty } from 'rambda';

import {
  CustomerAssignmentsFindManyParameter,
  CustomerAssignmentsRepository,
} from '../../repository/Assignments/CustomerAssignments.repository';

interface CustomerAssignmentsServiceFindManyParameter extends CustomerAssignmentsFindManyParameter {
  realmType: KaeplaDomainType;
  appRealmId: string;

  complete: boolean;
  enabled: boolean;
}

// export function useCustomerAssignmentsService(
//   parameter?: Partial<CustomerAssignmentsServiceFindManyParameter>,
// ) {
//   // const branding = new BrandingService();
//   const service = new CustomerAssignmentsService();
//   // const assignments = new KaeplaAssignmentsService();
//   // const customerRepo = new CustomerService();

//   // const [me, customer] = useSuspenseQueries({
//   //   queries: [assignments.me(), customerRepo.findMany()],
//   // });

//   // console.log('me', me.data);
//   // console.log('customer', customer.data);

//   return useSuspenseQuery(
//     service.findMany(
//       //   {
//       //   enabled: !!(me.data && customer.data),
//       //   ...parameter,
//       // }
//       { enabled: true },
//     ),
//   );
// }

export class CustomerAssignmentsService {
  private repository = new CustomerAssignmentsRepository();

  find(id: string) {
    return queryOptions({
      queryKey: [this.repository.path, id],
      queryFn: () => this.repository.find(id),
    });
  }

  //
  // without all parameters it is used for admins
  //
  // to determin admin we need KaeplaAssignmentsService.me()
  //
  // normal users requires
  //
  // - realmType
  // - appRealmId
  // - complete
  // - currentUser
  //
  // packages/webApp/src/Frontend/ListenerComponents/CustomerAssignmentsListenerUsers.tsx
  //
  //
  findMany({ enabled, ...parameter }: Partial<CustomerAssignmentsServiceFindManyParameter>) {
    const { appRealmId, realmType, where, ...rest } = parameter;
    const customerId = realmType === 'customer' ? appRealmId : undefined;
    const resellerId = realmType === 'reseller' ? appRealmId : undefined;

    return queryOptions({
      queryKey: isEmpty(parameter) ? [this.repository.path] : [this.repository.path, parameter],
      queryFn: () =>
        this.repository.findMany({
          ...rest,
          where: {
            customerId,
            resellerId,
            ...where,
          },
        }),

      enabled,
    });
  }
}
