import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreResellerListPath = '/resellers';
export type KaeplaFirestoreResellerPath = `${KaeplaFirestoreResellerListPath}/${string}`;

export interface KaeplaReseller {
  appName: string;
  createdAt: FirestoreTimestamp;
  createdBy: string;
  dataMatrixInstance: string;
  domains?: string[];
  iconBase64?: string;
  iconUrl?: string;
  isReadOnly?: boolean;
  id: string;
  linkUrl?: string;
  logoUrl?: string;
  name: string;
  projectCount: number;
  resellerHandleUid: string;
  updatedAt: FirestoreTimestamp;
}

export const kaeplaResellerSchema = z.object({
  appName: z.string(),
  createdAt: firestoreTimestampSchema,
  createdBy: z.string(),
  dataMatrixInstance: z.string(),
  domains: z.array(z.string()).optional(),
  iconBase64: z.string().optional(),
  iconUrl: z.string().optional(),
  isReadOnly: z.boolean().optional(),
  id: z.string(),
  linkUrl: z.string().optional(),
  logoUrl: z.string().optional(),
  name: z.string(),
  projectCount: z.number(),
  resellerHandleUid: z.string(),
  updatedAt: firestoreTimestampSchema,
}) satisfies z.ZodType<KaeplaReseller>;
