import { queryOptions } from '@tanstack/react-query';
import { isEmpty } from 'rambda';

import { queryClient } from '../config/client';
import {
  ProjectAssignmentFindManyParameter,
  ProjectAssignmentRepository,
} from '../repository/Assignments/ProjectAssignment.repository';

export class ProjectAssignmentService {
  repo = new ProjectAssignmentRepository();

  find(id: string) {
    return queryOptions({
      queryKey: [this.repo.path, id],
      queryFn: () => this.repo.find(id),
    });
  }

  findMany(parameter?: ProjectAssignmentFindManyParameter) {
    return queryOptions({
      queryKey: [this.repo.path, parameter],
      queryFn: () => this.repo.findMany(parameter),
    });
  }

  findManyUntilComplete(parameter?: ProjectAssignmentFindManyParameter) {
    return queryOptions({
      queryKey: [this.repo.path, parameter],
      queryFn: () => this.repo.findMany(parameter),
      refetchInterval(query) {
        if (isEmpty(query.state.data)) return 5000;
        return false;
      },
    });
  }

  async delete(id: string) {
    await this.repo.delete(id);
    await queryClient.invalidateQueries({
      queryKey: [this.repo.path, id],
    });
  }
}
