import { z } from 'zod';

import { MatrixJob, matrixJobSchema } from './MatrixJob.js';

export interface MatrixSimulationYears {
  // @rrust
  // !!! wrong type
  // years: number[];
  years: (number | string)[];
  job: MatrixJob;
}

export const matrixSimulationYearsSchema = z.object({
  years: z.number().or(z.string()).array(),
  job: matrixJobSchema,
}) satisfies z.ZodType<MatrixSimulationYears>;
