import { z } from 'zod';

import { MatrixPathDetail, matrixPathDetailSchema } from './MatrixPathDetail.js';

export interface MatrixPathDetails {
  scopePath?: string[];
  //
  //
  // @rrust
  // Why a tuple
  //
  pathDetails?: MatrixPathDetail[];
  error?: string;
}

export const matrixPathDetailsSchema = z.object({
  scopePath: z.string().array().optional(),
  pathDetails: matrixPathDetailSchema.array().optional(),
  error: z.string().optional(),
}) satisfies z.ZodType<MatrixPathDetails>;
