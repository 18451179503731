import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import { KaeplaFirestoreUserPath } from './KaeplaUser.js';

export type KaeplaFirestoreKaeplaUserAssignmentListPath =
  `${KaeplaFirestoreUserPath}/kaeplaAssignment`;

export type KaeplaFirestoreKaeplaUserAssignmentPath =
  `${KaeplaFirestoreKaeplaUserAssignmentListPath}/${string}`;

export interface KaeplaUserKaeplaAssignment {
  assignedAt: FirestoreTimestamp;
  canCreateReseller: boolean;
}

export const kaeplaUserKaeplaAssignmentSchema = z.object({
  assignedAt: firestoreTimestampSchema,
  canCreateReseller: z.boolean(),
}) satisfies z.ZodType<KaeplaUserKaeplaAssignment>;
