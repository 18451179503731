import { z } from 'zod';

export interface KaeplaDimensionDetail {
  value: string;
  numberOfRecords: number;
}

export const kaeplaDimensionDetailSchema = z.object({
  value: z.string(),
  numberOfRecords: z.number(),
}) satisfies z.ZodType<KaeplaDimensionDetail>;
