import { z } from 'zod';

import {
  customerAssignmentSchema,
  customerSchema,
  KaeplaCustomer,
  KaeplaCustomerAssignment,
  KaeplaProject,
  kaeplaProjectSchema,
  KaeplaUserProjectAssignment,
  userProjectAssignmentSchema,
} from '../Application';
import { WithCollectionId } from '../utilities';

export const getCustomersParametersSchema = z.object({
  uid: z.string(),
});

export type GetCustomersRequest = z.infer<typeof getCustomersParametersSchema>;

export interface GetCustomersResponse {
  customerAssignment: KaeplaCustomerAssignment[];
  projectAssignmentList: WithCollectionId<KaeplaUserProjectAssignment>[];
  customerList: KaeplaCustomer[];
  projectList: KaeplaProject[];
}

export const getCustomersResponseSchema: z.ZodType<GetCustomersResponse> = z.object({
  customerAssignment: customerAssignmentSchema.array(),
  projectAssignmentList: userProjectAssignmentSchema.and(z.object({ _id: z.string() })).array(),
  customerList: customerSchema.array(),
  projectList: kaeplaProjectSchema.array(),
});
