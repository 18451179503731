import { z } from 'zod';

export interface KaeplaSimulationTextValue {
  key: string;
  originalTextValue: string;
  simulatedTextValue: string;
}

export const kaeplaSimulationTextValueSchema = z.object({
  key: z.string(),
  originalTextValue: z.string(),
  simulatedTextValue: z.string(),
}) satisfies z.ZodType<KaeplaSimulationTextValue>;
