import { KaeplaCustomer, KaeplaFirestoreCustomerListPath } from '@kaepla/types';
import { limit, query, where } from 'firebase/firestore';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';
import { Where, WhereIn } from './base';
import { ProjectFindManyParameter } from './Project.repository';

export interface CustomerFindManyParameter {
  where?: Pick<Where<KaeplaCustomer>, 'id'>;
  in?: Pick<WhereIn<KaeplaCustomer>, 'id'>;
  limit?: number;
}

export class CustomerRepository extends FirebaseRepository<KaeplaCustomer> {
  entityPath: KaeplaFirestoreCustomerListPath = `/customers`;

  protected findManyQuery(parameter?: ProjectFindManyParameter) {
    let q = query(this.collection, limit(parameter?.limit ?? 100));

    if (parameter?.in?.id) {
      q = query(q, where('id', 'in', parameter.in.id));
    } else if (parameter?.where?.id) {
      q = query(q, where('id', '==', parameter.where.id));
    }

    return q;
  }
}
