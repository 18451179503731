import { KaeplaBranding } from '@kaepla/types';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Divider, Grid2 as Grid, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import { useAuth } from '../../../../../AuthReactProvider.js';
import { AuthProviderIcon } from '../../../User/features/AuthProviders/AuthProviderIcon.js';
import { oAuth2Providers } from '../../../User/features/AuthProviders/oAuth2Providers.js';

export function SamlSsoAuthenticationFlow({ branding }: { branding: KaeplaBranding | null }) {
  const {
    signInWithSamlSso,
    authError,
    clearAuthError,
    authProcedureError,
    clearAuthProcedureError,
  } = useAuth();
  const navigate = useNavigate();

  const samlProvider = branding?.authenticationConfiguration?.configuration.samlProvider;
  const providerPlatform = branding?.authenticationConfiguration?.configuration.providerPlatform;

  if (!samlProvider || !providerPlatform) {
    return <Box>Invalid SAML provider</Box>;
  }

  return (
    <form>
      <Grid container spacing={3} alignItems="center">
        {authError && (
          <Grid size={12}>
            <Alert
              variant="outlined"
              severity="error"
              data-testid="login-BrandedLoginErrorAlert"
              action={
                <IconButton
                  aria-label="close"
                  data-testid="login-BrandedLoginErrorAlertClose"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearAuthError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {authError.code}
            </Alert>
          </Grid>
        )}
        {authProcedureError && (
          <Grid size={12}>
            <Alert
              variant="outlined"
              severity="error"
              data-testid="login-BrandedLoginErrorAlert"
              action={
                <IconButton
                  aria-label="close"
                  data-testid="login-BrandedLoginErrorAlertClose"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearAuthProcedureError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {authProcedureError}
            </Alert>
          </Grid>
        )}
        <Grid container size={12} justifyContent="center">
          <Button
            size="medium"
            variant="contained"
            data-testid="login-BrandedLoginSamlSsoButton"
            onClick={() => {
              void signInWithSamlSso(samlProvider);
            }}
            startIcon={<AuthProviderIcon providerId={providerPlatform} />}
          >
            Single Sign-On
          </Button>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid size={12}>
          <Stack direction="row" justifyContent="center">
            {oAuth2Providers
              // .filter((p) => p.active === true)
              .map((provider) => {
                return (
                  <Box sx={{ m: 0.5 }} key={provider.providerId}>
                    <AuthProviderIcon providerId={provider.providerId} />
                  </Box>
                );
              })}
          </Stack>
          <Box display="flex" justifyContent="center">
            <Button
              variant="text"
              data-testid="login-LoginOptions"
              onClick={() => {
                void navigate(`/LoginOptions`);
              }}
            >
              login options
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
