import { z } from 'zod';

import { MatrixJob, matrixJobSchema } from './MatrixJob.js';
import { MatrixTimeseriesEntry, matrixTimeseriesEntrySchema } from './MatrixTimeseriesEntry.js';

export interface MatrixTimeseries {
  timeseries: MatrixTimeseriesEntry[];
  job: MatrixJob;
}

export const matrixTimeseriesSchema = z.object({
  timeseries: matrixTimeseriesEntrySchema.array(),
  job: matrixJobSchema,
}) satisfies z.ZodType<MatrixTimeseries>;
