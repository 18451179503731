import { z, ZodType } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreUserListPath = '/users';
export type KaeplaFirestoreUserPath = `${KaeplaFirestoreUserListPath}/${string}`;

export interface KaeplaUser {
  uid: string;
  displayName: string;
  acronym: string;
  email: string;
  color?: string;
  avatar?: string;
  base64Avatar?: string;
  updatedAt?: FirestoreTimestamp;
  resetPasswordToken?: string | null;
  settings?: Record<string, string | boolean | number>;
}

export type PartialKaeplaUser = Pick<
  KaeplaUser,
  'uid' | 'displayName' | 'acronym' | 'email' | 'updatedAt' | 'base64Avatar'
>;

export const kaeplaUserSchema = z.object({
  uid: z.string(),
  displayName: z.string(),
  acronym: z.string(),
  email: z.string(),
  color: z.string().optional(),
  avatar: z.string().optional(),
  base64Avatar: z.string().optional(),
  updatedAt: firestoreTimestampSchema.optional(),
  resetPasswordToken: z.string().nullable().optional(),
  settings: z.record(z.union([z.string(), z.boolean(), z.number()])).optional(),
}) satisfies ZodType<KaeplaUser>;
