import { useMemo } from 'react';

import { CustomerAssignmentsService } from '../service/Assignments/CustomerAssignments.service';
import { BrandingService } from '../service/Branding.service';
import { CustomerService } from '../service/Customer.service';
import { MatrixService } from '../service/Matrix.service';
import { ProjectService } from '../service/Project.service';
import { ProjectAssignmentService } from '../service/ProjectAssignment.service';
import { ResellerService } from '../service/Reseller.service';
import { UserService } from '../service/User.service';

export function useGetServices() {
  const userService = useMemo(() => new UserService(), []);
  const brandingService = useMemo(() => new BrandingService(), []);
  const matrixService = useMemo(() => new MatrixService(), []);
  const customerService = useMemo(() => new CustomerService(), []);
  const projectService = useMemo(() => new ProjectService(), []);
  const projectAssignmentService = useMemo(() => new ProjectAssignmentService(), []);
  const customerAssignmentsService = useMemo(() => new CustomerAssignmentsService(), []);
  const resellerService = useMemo(() => new ResellerService(), []);

  return {
    userService,
    brandingService,
    matrixService,
    customerService,
    projectService,
    projectAssignmentService,
    customerAssignmentsService,
    resellerService,
  };
}
