import { queryOptions } from '@tanstack/react-query';
import { prop, uniq } from 'rambda';

import { queryClient } from '../config/client';
import { ensure } from '../helpers/ensure';
import { IS_DEV } from '../mode';
import { AUTH_INSTANCE } from '../repository/base';
import { CustomerOnCallRepository } from '../repository/Customer.onCall.repository';
import { CustomerFindManyParameter, CustomerRepository } from '../repository/Customer.repository';

import { CustomerAssignmentsService } from './Assignments/CustomerAssignments.service';
import { BrandingService } from './Branding.service';
import { ConnectorService } from './Connector.service';

export class CustomerService {
  path = 'CustomerService';
  customerFirebaseRepo = new CustomerRepository();
  //
  // This is only available in development or test
  //
  // !!! this is onCall is still Work in progress
  //
  //
  customerOnCallRepo = new CustomerOnCallRepository();
  auth = AUTH_INSTANCE;
  brandingService = new BrandingService();
  customerAssignmentsService = new CustomerAssignmentsService();

  find(id: string) {
    return queryOptions({
      queryKey: [this.customerFirebaseRepo.path, id],
      queryFn: () => this.customerFirebaseRepo.find(id),
    });
  }

  findMany(parameter?: CustomerFindManyParameter) {
    return queryOptions({
      queryKey: [this.customerFirebaseRepo.path, parameter],
      queryFn: () => this.customerFirebaseRepo.findMany(parameter),
    });
  }

  findManyAssigned() {
    return queryOptions({
      queryKey: [this.path, 'assigned'],
      queryFn: () => this._findManyAssigned(),
    });
  }

  findManyByUser() {
    return queryOptions({
      queryKey: [this.path, 'byUser'],
      queryFn: () => this._findManyByUser(),
    });
  }

  findAllConnectors({ customerId }: { customerId: string }) {
    const connectorService = new ConnectorService({ customerId });

    return connectorService.findMany();
  }

  findConnector({ connectorId, customerId }: { connectorId: string; customerId: string }) {
    const connectorService = new ConnectorService({ customerId });

    return connectorService.find(connectorId);
  }

  private async _findManyByUser() {
    ensure(this.auth.currentUser, 'User not authenticated');
    ensure(IS_DEV, 'This function is only available in development');
    const result = await this.customerOnCallRepo.find(this.auth.currentUser.uid);

    return result;
  }

  private async _findManyAssigned() {
    const branding = await queryClient.ensureQueryData(
      this.brandingService.find(globalThis.location.hostname),
    );
    ensure(branding, 'Branding not found');
    ensure(this.auth.currentUser, 'User not authenticated');

    const customerAssignment = await queryClient.fetchQuery(
      this.customerAssignmentsService.findMany({
        appRealmId: branding.appRealmId,
        realmType: branding.realmType,
        currentUserId: this.auth.currentUser.uid,
        complete: true,
      }),
    );

    const idList = uniq(customerAssignment.map(prop('customerId')));

    const result = await Promise.all(idList.map((id) => queryClient.fetchQuery(this.find(id))));
    const customerList = result.filter((customer) => !!customer);

    return customerList;
  }
}
