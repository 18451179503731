import { KaeplaFirestoreResellerListPath, KaeplaReseller } from '@kaepla/types';
import { limit, query, where } from 'firebase/firestore';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';
import { WhereIn } from './base';

export interface ResellerFindManyParameter {
  in: Pick<WhereIn<KaeplaReseller>, 'id'>;
  where: Pick<WhereIn<KaeplaReseller>, 'id'>;
  limit?: number;
}

export class ResellerRepository extends FirebaseRepository<KaeplaReseller> {
  entityPath: KaeplaFirestoreResellerListPath = `/resellers`;

  protected findManyQuery(parameter?: ResellerFindManyParameter) {
    let q = query(this.collection, limit(parameter?.limit ?? 100));

    if (parameter?.in.id) {
      q = query(q, where('id', 'in', parameter.in.id));
    } else if (parameter?.where.id) {
      q = query(q, where('id', '==', parameter.where.id));
    }

    return q;
  }
}
