import { z } from 'zod';

export interface MatrixDimensionValues {
  values: string[];
  hasMore: boolean;
}

export const matrixDimensionValuesSchema = z.object({
  values: z.string().array(),
  hasMore: z.boolean(),
}) satisfies z.ZodType<MatrixDimensionValues>;
