import {
  KaeplaConnector,
  KaeplaCustomer,
  KaeplaProject,
  KaeplaProjectAssignment,
} from '@kaepla/types';
import { AnyUseMutationOptions, queryOptions } from '@tanstack/react-query';
import numbro from 'numbro';
import pluralize from 'pluralize';
import { isNotEmpty, prop } from 'rambda';

import { queryClient } from '../config/client';
import { getUserRootPaths } from '../Frontend/helpers/getUserRootPaths';
import { timeAgo } from '../Frontend/helpers/timeAgo';
import { delay } from '../helpers/delay';
import { ensure } from '../helpers/ensure';
import { AUTH_INSTANCE } from '../repository/base';
import {
  CreateProjectParameters,
  ProjectFindManyParameter,
  ProjectRepository,
} from '../repository/Project.repository';
import { MatrixService } from '../service/Matrix.service';

import { CustomerAssignmentsService } from './Assignments/CustomerAssignments.service';
import { CustomerService } from './Customer.service';
import { ProjectAssignmentService } from './ProjectAssignment.service';

export interface ProjectDTO extends KaeplaProject {
  customer: KaeplaCustomer | null;
  connector: KaeplaConnector | null;
  projectAssignmentList: KaeplaProjectAssignment[] | null;
  userRootPaths: Record<string, string>;

  //
  getSubHeader: string;
  formattedTotalRecordsCount: string;
  formattedDimensions: string;
  formattedAssignmentsCount: string;
}

interface ProjectDTOFindManyParameter {
  customerId?: string;
}

export class ProjectService {
  auth = AUTH_INSTANCE;
  //
  // REPOSITORIES
  //
  private projectRepository = new ProjectRepository();
  private customerAssignmentsService = new CustomerAssignmentsService();
  //
  // SERVICES
  //
  private projectAssignmentService = new ProjectAssignmentService();
  private customerService = new CustomerService();
  private matrixService = new MatrixService();

  get create() {
    return {
      mutationKey: [this.projectRepository.path, 'create'],
      mutationFn: (parameter: CreateProjectParameters) => this._create(parameter),
      throwOnError: true,
      async onSuccess(_data, variables: CreateProjectParameters) {
        //
        // invalidate all projectList DTOs
        //
        await queryClient.invalidateQueries({
          queryKey: ['ProjectListDto', { customerId: variables.customerId }],
        });
      },
    } satisfies AnyUseMutationOptions;
  }

  get renameProject() {
    return {
      mutationKey: [this.projectRepository.path, 'update'],
      mutationFn: ({ id, name }: { id: string; name: string }) =>
        this.projectRepository.update({ id, name }),
      throwOnError: true,
    } satisfies AnyUseMutationOptions;
  }

  get delete() {
    return {
      mutationKey: [this.projectRepository.path, 'delete'],
      mutationFn: async (id: string) => {
        await this.projectRepository.delete(id);
        //
        // delay for waiting trigger for remove projectAssignment
        //
        await delay(2000);
        return null;
      },
      throwOnError: true,
    } satisfies AnyUseMutationOptions;
  }

  find(id: string) {
    return queryOptions({
      queryKey: [this.projectRepository.path, id],
      queryFn: () => this.projectRepository.find(id),
    });
  }

  findMany(parameter?: ProjectFindManyParameter) {
    return queryOptions({
      queryKey: [this.projectRepository.path, parameter],
      queryFn: () => this.projectRepository.findMany(parameter),
    });
  }

  findProjectDTO(id: string) {
    return queryOptions({
      queryKey: ['ProjectDto', id],
      queryFn: () => this._findProjectDTO(id),
    });
  }

  findManyProjectDTO(
    parameter?: ProjectDTOFindManyParameter,
    query?: { refetchInterval: number | string | undefined },
  ) {
    const refetchInterval = query?.refetchInterval ? Number(query?.refetchInterval) : undefined;

    return queryOptions({
      queryKey: ['ProjectListDto', parameter],
      queryFn: () => this._findManyDto(parameter),
      refetchInterval,
    });
  }

  private async _create(parameter: CreateProjectParameters) {
    const customer = await queryClient.fetchQuery(this.customerService.find(parameter.customerId));
    ensure(this.auth.currentUser, 'User not authenticated');
    ensure(customer, 'Customer not found');
    ensure(customer.resellerId, 'Customer has no reseller');

    ensure(
      parameter.resellerId === customer.resellerId,
      "Reseller id doesn't match with payload mismatch",
    );

    const assignment = await queryClient.fetchQuery(
      this.customerAssignmentsService.findMany({
        currentUserId: this.auth.currentUser.uid,
        where: {
          customerId: parameter.customerId,
        },
      }),
    );
    ensure(isNotEmpty(assignment), 'User has no assignment for this customer');
    return this.projectRepository.create(parameter);
  }

  private async _findManyDto(parameter?: ProjectDTOFindManyParameter): Promise<ProjectDTO[]> {
    ensure(this.auth.currentUser, 'User not authenticated');
    const assignments = await queryClient.fetchQuery(
      this.projectAssignmentService.findMany({
        where: {
          uid: this.auth.currentUser.uid,
          customerId: parameter?.customerId,
          complete: true,
        },
      }),
    );

    const projectIdList = assignments.map(prop('projectId'));
    const projectResult = await Promise.all(
      projectIdList.map((id) => queryClient.fetchQuery(this.find(id))),
    );
    const projects = projectResult.filter((project) => !!project);
    const matrix = await queryClient.fetchQuery(this.matrixService.findManyMeta(projectIdList));

    // fetch connectorList
    // first we need to make sure we only fetch the connector once
    const customerConnectorMap = new Map<string, Set<string>>();
    projects.forEach((project) => {
      if (!project.connectorId || !project.customerId) {
        return;
      }
      if (!customerConnectorMap.has(project.customerId)) {
        customerConnectorMap.set(project.customerId, new Set());
      }
      customerConnectorMap.get(project.customerId)!.add(project.connectorId);
    });
    const customerIdList = [...customerConnectorMap.entries()].flatMap(
      ([customerId, connectionSet]) =>
        [...connectionSet].map((connectorId) => [customerId, connectorId]),
    );

    const connectorResult = await Promise.all(
      customerIdList.map(([customerId, connectorId]) =>
        queryClient.fetchQuery(this.customerService.findConnector({ connectorId, customerId })),
      ),
    );
    const connectorList = connectorResult.filter((connector) => !!connector);

    return (
      projects
        ///
        /// !!! TODO
        ///
        /// project schema should have createdAt,name, updatedAt field required
        ///
        .filter((c) => c.name !== undefined)
        .slice()
        //
        // sort by date instead of name
        //
        // if user renames project it could change position
        //
        .sort((a, b) => {
          if (!a.createdAt) return 0;
          if (!b.createdAt) return 1;
          return a.createdAt.seconds - b.createdAt.seconds;
        })

        .map((project) =>
          createProjectDto({
            project,
            customer: matrix.find((meta) => meta.projectId === project.id)?.customer,
            assignments,
            connector: connectorList.find(
              (connector) =>
                connector.customerId === project.customerId && connector.id === project.connectorId,
            ),
          }),
        )
    );
  }

  private async _findProjectDTO(id: string): Promise<ProjectDTO> {
    ensure(this.auth.currentUser, 'User not authenticated');
    const assignments = await queryClient.fetchQuery(
      this.projectAssignmentService.findMany({
        where: {
          uid: this.auth.currentUser.uid,
          projectId: id,
          complete: true,
        },
      }),
    );
    const project = await queryClient.fetchQuery(this.find(id));

    ensure(project, 'Project not found');

    const meta = await queryClient.fetchQuery(this.matrixService.findMeta(id));
    ensure(meta, 'meta for project not found');

    let connector: KaeplaConnector | undefined;
    if (project.connectorId) {
      const foundConnector = await queryClient.fetchQuery(
        this.customerService.findConnector({
          customerId: project.customerId,
          connectorId: project.connectorId,
        }),
      );
      connector = foundConnector ?? undefined;
    }

    return createProjectDto({ project, customer: meta.customer, assignments, connector });
  }
}

function createProjectDto({
  project,
  customer,
  assignments,
  connector,
}: {
  project: KaeplaProject;
  customer: KaeplaCustomer | undefined;
  assignments: KaeplaProjectAssignment[];
  connector?: KaeplaConnector;
}) {
  return {
    ...project,
    customer: customer ?? null,
    connector: connector ?? null,
    //
    // should be already filtered
    //
    projectAssignmentList:
      assignments.filter((assignment) => assignment.projectId === project.id) ?? null,
    userRootPaths: getUserRootPaths(assignments),

    getSubHeader: getSubHeader({
      matrixInPreparation: project.matrixInPreparation,
      matrixUnavailable: project.matrixUnavailable,
      lastUpdatedAt: project.lastUpdatedAt,
    }),
    formattedTotalRecordsCount: formattedTotalRecordsCount(project.totalRecordsCount),
    formattedDimensions: project.totalDimensionsCount
      ? `${project.totalDimensionsCount} dimensions`
      : 'No dimensions',
    formattedAssignmentsCount: formattedAssignmentsCount(assignments, project.id),
  };
}

export function formattedAssignmentsCount(
  assignments: KaeplaProjectAssignment[],
  projectId: string,
) {
  const count = assignments.filter((assignment) => assignment.projectId === projectId).length;
  return `${pluralize('assignment', count, true)}`;
}

export function getSubHeader(
  project?: Partial<
    Pick<KaeplaProject, 'matrixInPreparation' | 'matrixUnavailable' | 'lastUpdatedAt'>
  >,
) {
  if (project?.matrixInPreparation) {
    return 'data is being prepared now';
  }
  if (project?.matrixUnavailable) {
    return 'no data available';
  }
  if (project?.lastUpdatedAt) {
    return 'updated ' + timeAgo(project.lastUpdatedAt) || 'never';
  }
  return 'not set up yet';
}

export function formattedTotalRecordsCount(number?: number) {
  if (!number) {
    return 'No record';
  }
  if (number === 0) {
    return 'No record';
  }
  return `${numbro(number).format({
    average: true,
    mantissa: 1,
    trimMantissa: true,
  })} records`;
}
