import {
  KaeplaApiParameters,
  KaeplaQueryType,
  MatrixApiResponse,
  matrixApiResponseSchema,
} from '@kaepla/types';
import { isEmpty } from 'rambda';

import { logger } from '../Frontend/helpers/logger';

import { HttpsCallableRepository } from './AbstractClasses/HttpsCallable.repository';

export class MatrixRepository extends HttpsCallableRepository<
  KaeplaApiParameters,
  MatrixApiResponse
> {
  path = 'getKaeplaMatrixV2';
  schemaResponse = matrixApiResponseSchema;

  async find(parameters: KaeplaApiParameters): Promise<MatrixApiResponse | null> {
    try {
      const result = await this.fetch(parameters);

      if (isEmpty(result)) {
        return null;
      }

      // const parsed = brandingSchema.parse(result) satisfies KaeplaBranding | null;
      // await this.client.invalidateQueries({ queryKey: [this.path, hostname] });
      return result;
    } catch (error) {
      logger.error('Branding repository', error);
      throw error;
    }
  }

  async findMeta(projectId: string) {
    return this.find({
      q: 'meta' as KaeplaQueryType,
      projectId,
      //
      s: 'ProjectCard',
    });
  }

  async findManyMeta(idList: string[]) {
    const promiseList = await Promise.all(
      idList.sort().map((projectId) =>
        this.find({
          q: 'meta' as KaeplaQueryType,
          projectId,
          //
          s: 'ProjectCard',
        }),
      ),
    );

    return promiseList.filter((response) => response !== null);
  }
}
