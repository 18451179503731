import { z } from 'zod';

import { KaeplaDimension, dimensionSchema } from './Application/KaeplaDimension.js';
import { MatrixJob, matrixJobSchema } from './MatrixJob.js';

export interface MatrixData {
  rows: Record<string, unknown>[];
  rowCount: number;
  dimensions: KaeplaDimension[];
  job: MatrixJob;
}

export const matrixDataSchema = z.object({
  rows: z.record(z.string(), z.unknown()).array(),
  rowCount: z.number(),
  dimensions: dimensionSchema.array(),
  job: matrixJobSchema,
}) satisfies z.ZodType<MatrixData>;
