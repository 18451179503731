import { KaeplaFirestoreProjectAssignmentListPath, KaeplaProjectAssignment } from '@kaepla/types';
import { limit, query, where } from 'firebase/firestore';

import { FirebaseRepository } from '../AbstractClasses/FirebaseRepository';
import { Where, WhereIn } from '../base';

export interface ProjectAssignmentFindManyParameter {
  where?: Pick<
    Where<KaeplaProjectAssignment>,
    'id' | 'uid' | 'complete' | 'scopePathStringified' | 'projectId' | 'customerId'
  >;
  in?: Pick<WhereIn<KaeplaProjectAssignment>, 'id' | 'complete' | 'scopePathStringified'>;
  limit?: number;
}

export class ProjectAssignmentRepository extends FirebaseRepository<KaeplaProjectAssignment> {
  entityPath: KaeplaFirestoreProjectAssignmentListPath = `/projectAssignments`;

  protected findManyQuery(parameter?: ProjectAssignmentFindManyParameter) {
    let q = query(this.collection, limit(parameter?.limit ?? 100));

    if (parameter?.where?.uid) {
      q = query(q, where('uid', '==', parameter.where.uid));
    }

    if (parameter?.where?.customerId) {
      q = query(q, where('customerId', '==', parameter.where.customerId));
    }

    //
    //
    //
    if (parameter?.where?.projectId) {
      q = query(q, where('projectId', '==', parameter.where.projectId));
    }

    if (parameter?.where?.complete) {
      q = query(q, where('complete', '==', parameter.where.complete));
    } else if (parameter?.in?.complete) {
      q = query(q, where('complete', 'in', parameter.in.complete));
    }

    if (parameter?.where?.id) {
      q = query(q, where('id', '==', parameter.where.id));
    } else if (parameter?.in?.id) {
      q = query(q, where('id', 'in', parameter.in.id));
    }

    if (parameter?.where?.scopePathStringified) {
      q = query(q, where('scopePathStringified', 'in', parameter.where.scopePathStringified));
    } else if (parameter?.in?.scopePathStringified) {
      q = query(q, where('scopePathStringified', 'in', parameter.in.scopePathStringified));
    }

    return q;
  }
}
