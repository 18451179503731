import { z } from 'zod';

import { KaeplaCustomerAuthenticationProviderPlatform } from './KaeplaCustomerAuthenticationProviderPlatform';

export interface KaeplaSamlSsoAuthenticationConfig {
  samlProvider: string;
  providerPlatform: KaeplaCustomerAuthenticationProviderPlatform;
  handledDomains: string[];
}

export const customerAuthenticationMethodSchema = z.object({
  samlProvider: z.string(),
  handledDomains: z.string().array(),
  providerPlatform: z.nativeEnum(KaeplaCustomerAuthenticationProviderPlatform),
}) satisfies z.ZodType<KaeplaSamlSsoAuthenticationConfig>;
