/* eslint-disable max-classes-per-file */
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { z } from 'zod';
import { toValidationError } from 'zod-validation-error';

import { AUTH_INSTANCE, FIRESTORE_INSTANCE } from '../base';

export class FirebasePermissionError extends Error {
  path: string;
  parameter?: unknown;
  error: unknown;

  constructor(message: string, path: string, error: unknown, parameter?: unknown) {
    super(message);
    this.name = 'FirebasePermissionError';
    this.path = path;
    this.parameter = parameter;
    this.error = error;
  }
}
export class NotFoundError extends Error {
  entity: string;
  constructor(message: string, entity: string) {
    super(message);
    this.name = 'NotFoundError';
    this.entity = entity;
  }
}

export function isNotFoundError(error: unknown): error is NotFoundError {
  if (typeof error !== 'object') return false;
  if (!error) return false;
  if ('name' in error && error.name === 'NotFoundError') return true;
  return false;
}

export function isFirebaseError(error: unknown): error is { code: string; message: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    'message' in error &&
    typeof (error as { code: unknown; message: string }).code === 'string'
  );
}

export abstract class FirebaseRepository<T extends DocumentData> {
  rootPath: string;

  protected firebase = FIRESTORE_INSTANCE;
  protected auth = AUTH_INSTANCE;
  protected schema?: z.ZodType<T> = undefined;

  abstract entityPath: string;

  constructor(rootPath?: string) {
    this.rootPath = rootPath ?? '';
  }

  get converter() {
    return {
      toFirestore: (data: T) => {
        if (!this.schema) return data;
        return this.schema.parse(data);
      },
      fromFirestore: (snap: QueryDocumentSnapshot<T>) => snap.data(),
    };
  }

  get path() {
    return `${this.rootPath}${this.entityPath}`;
  }

  get collection() {
    return collection(this.firebase, this.path).withConverter<T>(this.converter);
  }

  get reference() {
    return doc(this.collection);
  }

  findQuery(id: string) {
    return doc(this.collection, id);
  }

  async find(id: string) {
    try {
      const response = await getDoc(this.findQuery(id));
      const data = response.data();
      if (this.schema) {
        return this.parse(data, this.schema);
      }

      if (data === undefined) {
        return null;
      }

      return data;
    } catch (error: unknown) {
      if (isFirebaseError(error)) {
        throw new FirebasePermissionError(error.message, this.path, error, { id });
      }
      throw error;
    }
  }

  async findMany(parameter?: unknown) {
    try {
      const response = await getDocs(this.findManyQuery(parameter));
      const data = response.docs.map((document_) => {
        return {
          _id: document_.id,
          ...document_.data(),
        };
      });
      if (this.schema) {
        return this.parse(data, this.schema.array());
      }
      return data;
    } catch (error: unknown) {
      if (isFirebaseError(error)) {
        throw new FirebasePermissionError(error.message, this.path, error, parameter);
      }
      throw error;
    }
  }

  async create(parameter: Omit<T, 'id'>, reference?: DocumentReference<T, DocumentData>) {
    const reference_ = reference ?? this.reference;
    const payload = { ...parameter, id: reference_.id };
    try {
      if (this.schema) {
        const data = this.parse(
          {
            ...payload,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
          },
          this.schema,
        );
        await setDoc(reference_, data);
      } else {
        await setDoc(reference_, {
          ...payload,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        } as unknown as T);
      }
      return payload;
    } catch (error: unknown) {
      if (isFirebaseError(error)) {
        throw new FirebasePermissionError(error.message, this.path, error, parameter);
      }
      throw error;
    }
  }

  async update(parameter: Partial<T> & { id: string }) {
    const reference_ = this.findQuery(parameter.id);
    const payload = { ...parameter, id: reference_.id };
    try {
      if (this.schema) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const schema = this.schema as unknown as z.ZodObject<any>;
        const data = this.parse(payload, schema.partial());
        await updateDoc(reference_, {
          ...data,
          updatedAt: Timestamp.now(),
        });
      } else {
        await updateDoc(reference_, payload as unknown as T);
      }
      return payload;
    } catch (error: unknown) {
      if (isFirebaseError(error)) {
        throw new FirebasePermissionError(error.message, this.path, error, parameter);
      }
      throw error;
    }
  }

  async delete(id: string) {
    await deleteDoc(this.findQuery(id));
  }

  //
  // Convert to ValidationError
  //
  private parse<P>(value: unknown, schema: z.ZodType<P>) {
    if (!value) {
      throw new NotFoundError(`Not found [${this.path}]`, this.path);
    }
    const result = schema.safeParse(value);
    if (result.success) {
      return result.data;
    }
    throw toValidationError({
      prefix: `Validation error [${this.path}]`,
      issueSeparator: '\n',
      prefixSeparator: '\n\n',
    })(result.error);
  }
  protected abstract findManyQuery(parameter?: unknown): Query<T, DocumentData>;
}
