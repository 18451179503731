import {
  GetCustomersRequest,
  GetCustomersResponse,
  getCustomersResponseSchema,
} from '@kaepla/types';

import { HttpsCallableRepository } from './AbstractClasses/HttpsCallable.repository';

export class CustomerOnCallRepository extends HttpsCallableRepository<
  GetCustomersRequest,
  GetCustomersResponse
> {
  path = 'getCustomers';
  schemaResponse = getCustomersResponseSchema;

  async find(uid: string) {
    const result = await this.fetch({
      uid,
    });

    return result;
  }
}
