import { z } from 'zod';

import { kaeplaDataGridRequestParametersSchema } from './KaeplaDataGridRequestParameters.js';

export const kaeplaGetDataParametersSchema = z.object({
  projectId: z.string(),
  simulationId: z.string().optional().nullable(),
  snapshotId: z.string().optional().nullable(),
  scopePath: z.array(z.string()),
  filterSql: z.string().optional().nullable(),
  dataGridRequestParameters: kaeplaDataGridRequestParametersSchema,
  selectedColumns: z.array(z.string()),
  searchTerm: z.string().optional().nullable(),
  downloadAsFile: z.boolean().optional().nullable(),
  info: z.string().optional().nullable(),
});

export type KaeplaGetDataParameters = z.infer<typeof kaeplaGetDataParametersSchema>;
