import { z, ZodType } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';
import { MatrixTimeSlice } from '../MatrixTimeSlice.js';

import { KaeplaPerspectiveBlock, perspectiveBlockSchema } from './KaeplaPerspectiveBlock.js';
import { KaeplaFirestoreProjectPath } from './KaeplaProject.js';

export type KaeplaPerspectiveListPath = `${KaeplaFirestoreProjectPath}/perspectives`;
export type KaeplaPerspectivePath = `${KaeplaPerspectiveListPath}/${string}`;

export interface KaeplaPerspective {
  aggregation?: string;
  aggregationOverTimeName: string;
  blocks?: KaeplaPerspectiveBlock[];
  createdAt: FirestoreTimestamp;
  createdBy: string;
  description?: string;
  id: string;
  identifierDimension: string;
  locked?: boolean;
  name: string;
  projectId?: string;
  scopePath: string[];
  scopePathStringified: string;
  timeDimension: string;
  timeDimensionLabel?: string;
  timeSlice: MatrixTimeSlice;
  updatedAt?: FirestoreTimestamp;
  valueDimension: string;
  valueDimensionLabel?: string;
}

export const perspectiveSchema = z.object({
  aggregation: z.string().optional(),
  aggregationOverTimeName: z.string(),
  blocks: perspectiveBlockSchema.array().optional(),
  createdAt: firestoreTimestampSchema,
  createdBy: z.string(),
  description: z.string().optional(),
  id: z.string(),
  identifierDimension: z.string(),
  locked: z.boolean().optional(),
  name: z.string(),
  projectId: z.string().optional(),
  scopePath: z.array(z.string()),
  scopePathStringified: z.string(),
  timeDimension: z.string(),
  timeDimensionLabel: z.string().optional(),
  timeSlice: z.nativeEnum(MatrixTimeSlice),
  updatedAt: firestoreTimestampSchema.optional(),
  valueDimension: z.string(),
  valueDimensionLabel: z.string().optional(),
}) satisfies ZodType<KaeplaPerspective>;
