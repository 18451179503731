import { KaeplaBranding } from '@kaepla/types';
import { Grid2 as Grid } from '@mui/material';

import { AuthenticationFlow } from './AuthenticationFlow.js';
import { BrandedLoginHeader } from './BrandedLoginHeader.js';

export function CustomBrandedLogin({ branding }: { branding: KaeplaBranding | null }) {
  return (
    <Grid container spacing={3} data-testid="login-BrandedLogin" alignItems="center">
      <Grid size={12}>
        <BrandedLoginHeader branding={branding} />
      </Grid>
      <Grid size={12}>
        <AuthenticationFlow branding={branding} />
      </Grid>
    </Grid>
  );
}
