const isLocalhost =
  globalThis.location.host.startsWith('localhost:') || globalThis.location.host.includes('.local');

export const IS_DEV = import.meta.env.DEV;

export const IS_CI = import.meta.env.VITE_CI === 'true' || (isLocalhost && !IS_DEV);

export const SHOULD_EMULATE_FUNCTIONS = import.meta.env.VITE_EMULATE_FUNCTIONS === 'true';
export const SHOULD_EMULATE_FIRESTORE = import.meta.env.VITE_EMULATE_FIRESTORE === 'true';

export const IS_EMULATOR = import.meta.env.VITE_WITH_EMULATOR === 'true';
