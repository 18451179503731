import { KaeplaUser } from '@kaepla/types';
import { DefaultError, queryOptions, type UseMutationOptions } from '@tanstack/react-query';

import { queryClient } from '../config/client';
import { ensure } from '../helpers/ensure';
import { UserRepository } from '../repository/User.repository';

import { ProjectAssignmentService } from './ProjectAssignment.service';
import { UserProjectAssignmentService } from './UserProjectAssignment.service';

export function mutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationOptions<TData, TError, TVariables, TContext> {
  return options;
}

export class UserService {
  repo = new UserRepository();
  projectAssignmentService = new ProjectAssignmentService();
  //
  // !!! BUG
  // todo userProjectAssignment should be in sync with projectAssignmentService
  //
  get userProjectAssignment() {
    const path = this.repo.getCurrentUserPath();

    return new UserProjectAssignmentService(`/${path}`);
  }

  currentUser() {
    return queryOptions({
      queryKey: ['UserService', 'currentUser'],
      queryFn: () => this._currentUser(),
    });
  }

  find(id: string) {
    return queryOptions({
      queryKey: [this.repo.path, id],
      queryFn: () => this.repo.find(id),
    });
  }

  findMany(parameter?: unknown) {
    return queryOptions({
      queryKey: [this.repo.path, parameter],
      queryFn: () => this.repo.findMany(parameter),
    });
  }

  findTeamMembers(projectId: string) {
    return queryOptions({
      queryKey: ['UserService', 'findTeamMembers', projectId],
      queryFn: () => this._findTeamMembers(projectId),
    });
  }

  //
  // MUTATION
  //
  async leaveProject(projectId: string, _userId?: string) {
    const me = await queryClient.fetchQuery(this.currentUser());
    ensure(me, 'User not authenticated');

    const projectAssignmentList = await queryClient.fetchQuery(
      this.projectAssignmentService.findMany({
        //
        // cant be a string ???
        //
        // in: {
        //   scopePathStringified: isEmpty(properties.defaultPath)
        //     ? undefined
        //     : properties.defaultPath,
        // },
        where: {
          projectId,
        },
      }),
    );

    const assignment = projectAssignmentList.find((_assignment) => _assignment.uid === me.uid);

    ensure(assignment, 'User not assigned to project');

    await this.projectAssignmentService.delete(assignment.id);

    await queryClient.invalidateQueries({
      queryKey: ['UserService', 'findTeamMembers', projectId],
    });
  }

  private async _findTeamMembers(projectId: string): Promise<KaeplaUser[]> {
    const me = await queryClient.fetchQuery(this.currentUser());
    ensure(me, 'User not authenticated');
    const projectAssignmentList = await queryClient.fetchQuery(
      this.projectAssignmentService.findMany({
        //
        // cant be a string ???
        //
        // in: {
        //   scopePathStringified: isEmpty(properties.defaultPath)
        //     ? undefined
        //     : properties.defaultPath,
        // },
        where: {
          projectId,
        },
      }),
    );

    const promiseList = await Promise.all(
      projectAssignmentList.map((assignment) => queryClient.fetchQuery(this.find(assignment.uid))),
    );

    return promiseList
      .filter((user) => !!user)
      .sort((a, b) => {
        return a.uid === me.uid ? -1 : b.uid === me.uid ? 1 : 0;
      });
  }

  private async _currentUser() {
    return this.repo.getCurrentUser();
  }
}
