import { z } from 'zod';

import {
  KaeplaDimensionDetail,
  kaeplaDimensionDetailSchema,
} from './Application/KaeplaDimensionDetail.js';

export interface MatrixPathDetail {
  pathPart: string[];
  detail: KaeplaDimensionDetail[];
}

export const matrixPathDetailSchema = z.object({
  pathPart: z.string().array(),
  detail: kaeplaDimensionDetailSchema.array(),
}) satisfies z.ZodType<MatrixPathDetail>;
