import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isEmpty } from 'rambda';
import { z } from 'zod';
import { toValidationError } from 'zod-validation-error';

import { logger } from '../../Frontend/helpers/logger';

export abstract class HttpsCallableRepository<RequestData, ResponseData> {
  private app = getApp('kaepla');
  private functions = getFunctions(this.app, 'europe-west3');
  abstract path: string;
  abstract schemaResponse: z.ZodType<ResponseData>;

  protected async fetch(data: RequestData): Promise<ResponseData | null> {
    try {
      const callable = httpsCallable<RequestData, ResponseData>(this.functions, this.path);
      const result = await callable(data);
      if (result.data === undefined) return null;
      //
      // HACK for branding endpoint
      //
      // TODO return 404 in Branding endpoint if host was not found
      //
      if (isEmpty(result.data)) return null;
      //
      return this.parse(result.data, this.schemaResponse);
    } catch (error) {
      logger.error('Request', data);
      logger.error('Response', error);
      throw error;
    }
  }

  //
  // Convert to ValidationError
  //
  private parse<P>(value: P, schema: z.ZodTypeAny) {
    const result = schema.safeParse(value);
    if (result.success) {
      return result.data as P;
    }
    throw toValidationError({
      prefix: `Validation error [${this.path}]`,
      issueSeparator: '\n',
      prefixSeparator: '\n\n',
    })(result.error);
  }
}
