import { z } from 'zod';

import { MatrixTimeSlice } from '../MatrixTimeSlice.js';

export interface KaeplaSimulationPercentage {
  key: string;
  timeSlice: MatrixTimeSlice;
  dateFrom: string;
  dateUntil: string;
  percentage: number;
  absoluteValue?: number;
  originalValue?: number;
  currency?: string;
  decimalPositions?: number;
}

export const kaeplaSimulationPercentageSchema = z.object({
  key: z.string(),
  timeSlice: z.nativeEnum(MatrixTimeSlice),
  dateFrom: z.string(),
  dateUntil: z.string(),
  percentage: z.number(),
  absoluteValue: z.number().optional(),
  originalValue: z.number().optional(),
  currency: z.string().optional(),
  decimalPositions: z.number().optional(),
}) satisfies z.ZodType<KaeplaSimulationPercentage>;
