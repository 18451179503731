import { z, ZodType } from 'zod';

import { KaeplaPerspectiveBlockType } from './KaeplaPerspectiveBlockType.js';

export interface KaeplaPerspectiveBlock {
  type: KaeplaPerspectiveBlockType;
  aggregationDimension?: string;
  aggregationDimensionLabel?: string;
  aggregation: 'SUM' | 'AVG' | 'MIN' | 'MAX';
  valueDimension?: string;
  valueDimensionLabel?: string | null;
  timeDimension?: string;
  timeDimensionLabel?: string | null;
  splitDimension?: string;
  splitDimensionLabel?: string;
  gridSpan?: 'small' | 'medium' | 'large';
  filterId?: string;
}
export const perspectiveBlockSchema = z.object({
  type: z.nativeEnum(KaeplaPerspectiveBlockType),
  aggregationDimension: z.string().optional(),
  aggregationDimensionLabel: z.string().optional(),
  aggregation: z.enum(['SUM', 'AVG', 'MIN', 'MAX']),
  valueDimension: z.string().optional(),
  valueDimensionLabel: z.string().optional().nullable(),
  timeDimension: z.string().optional(),
  timeDimensionLabel: z.string().optional().nullable(),
  splitDimension: z.string().optional(),
  splitDimensionLabel: z.string().optional(),
  gridSpan: z.enum(['small', 'medium', 'large']).optional(),
  filterId: z.string().optional(),
}) satisfies ZodType<KaeplaPerspectiveBlock>;
