import { z, ZodType } from 'zod';

import { CurrencyIsoCode, currencyIsoCodeSchema } from '../CurrencyIsoCode.js';
import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';
import { MatrixSimulationYears, matrixSimulationYearsSchema } from '../MatrixSimulationYears.js';

import { dimensionSchema, KaeplaDimension } from './KaeplaDimension.js';
import { KaeplaModel, kaeplaModelSchema } from './KaeplaModel.js';
import { KaeplaPerspective, perspectiveSchema } from './KaeplaPerspective.js';

export type KaeplaFirestoreProjectListPath = '/projects';
export type KaeplaFirestoreProjectPath = `${KaeplaFirestoreProjectListPath}/${string}`;

export interface KaeplaProject {
  accessDomain: string;
  connectorId?: string;
  createdAt?: FirestoreTimestamp;
  createdBy: string;
  currentImportId?: string | null;
  customerId: string;
  dataMatrix: string;
  defaultPerspective?: KaeplaPerspective;
  dimensions?: KaeplaDimension[];
  dimensionsUpdateInProgress?: boolean;
  disabledReason?: 'licenseExpired' | 'maintenance';
  features?: string[];
  fullUpdateIntervalInHours?: number;
  id: string;
  importConfigurationId?: string;
  initializedAt?: FirestoreTimestamp;
  lastExportRequestedAt?: FirestoreTimestamp;
  lastFullUpdateAt?: FirestoreTimestamp;
  lastUpdatedAt?: FirestoreTimestamp;
  matrixInPreparation?: boolean;
  matrixUnavailable?: boolean;
  model?: KaeplaModel;
  name: string;
  ownedBy?: string;
  projectCurrency?: CurrencyIsoCode;
  resellerId: string;
  simulationYears?: MatrixSimulationYears;
  totalDimensionsCount?: number;
  totalRecordsCount?: number;
  updatedAt?: FirestoreTimestamp;
}

export const kaeplaProjectSchema = z.object({
  accessDomain: z.string(),
  connectorId: z.string().optional(),
  createdAt: firestoreTimestampSchema.optional(),
  createdBy: z.string(),
  currentImportId: z.string().nullable().optional(),
  customerId: z.string(),
  dataMatrix: z.string(),
  defaultPerspective: perspectiveSchema.optional(),
  dimensions: dimensionSchema.array().optional(),
  dimensionsUpdateInProgress: z.boolean().optional(),
  disabledReason: z.enum(['licenseExpired', 'maintenance']).optional(),
  features: z.array(z.string()).optional(),
  fullUpdateIntervalInHours: z.number().optional(),
  id: z.string(),
  importConfigurationId: z.string().optional(),
  initializedAt: firestoreTimestampSchema.optional(),
  lastExportRequestedAt: firestoreTimestampSchema.optional(),
  lastFullUpdateAt: firestoreTimestampSchema.optional(),
  lastUpdatedAt: firestoreTimestampSchema.optional(),
  matrixInPreparation: z.boolean().optional(),
  matrixUnavailable: z.boolean().optional(),
  model: kaeplaModelSchema.optional(),
  name: z.string(),
  ownedBy: z.string().optional(),
  projectCurrency: currencyIsoCodeSchema.optional(),
  resellerId: z.string(),
  simulationYears: matrixSimulationYearsSchema.optional(),
  totalDimensionsCount: z.number().optional().default(0),
  totalRecordsCount: z.number().optional().default(0),
  updatedAt: firestoreTimestampSchema.optional(),
}) satisfies ZodType<KaeplaProject>;
// .brand<'KaeplaProject'>();
