import { z } from 'zod';

import {
  authenticationConfigSchema,
  KaeplaCustomerAuthenticationConfig,
} from './KaeplaCustomerAuthenticationConfig.js';
import { KaeplaDomainType } from './KaeplaDomainType.js';

export interface KaeplaBranding {
  appIconBase64: string | null;
  appLogoUrl: string | null;
  appName: string;
  appProvider: string;
  appRealm: string;
  appRealmId: string;
  authenticationConfiguration?: KaeplaCustomerAuthenticationConfig;
  providerIconBase64?: string | null;
  providerId: string;
  providerLinkUrl?: string | null;
  providerLogoUrl?: string | null;
  realmType: KaeplaDomainType;
}

export const brandingSchema = z.object({
  appIconBase64: z.string().nullable(),
  appLogoUrl: z.string().nullable(),
  appName: z.string(),
  appProvider: z.string(),
  appRealm: z.string(),
  appRealmId: z.string(),
  authenticationConfiguration: authenticationConfigSchema.optional(),
  providerIconBase64: z.string().optional().nullable(),
  providerId: z.string(),
  providerLinkUrl: z.string().optional().nullable(),
  providerLogoUrl: z.string().optional().nullable(),
  realmType: z.nativeEnum(KaeplaDomainType),
}) satisfies z.ZodType<KaeplaBranding>;
