import { queryOptions } from '@tanstack/react-query';

import { UserProjectAssignmentRepository } from '../repository/UserProjectAssignment.repository';

export class UserProjectAssignmentService {
  repo: UserProjectAssignmentRepository;
  constructor(path?: string) {
    this.repo = new UserProjectAssignmentRepository(path);
  }

  find(id: string) {
    return queryOptions({
      queryKey: [this.repo.path, id],
      queryFn: () => this.repo.find(id),
    });
  }

  // why can't user access his own projectAssignment?

  //  match /users/{kaeplaUser}/projectAssignment/{document=**} {
  //   allow read: if true;
  // }
  findMany() {
    return queryOptions({
      queryKey: [this.repo.path],
      queryFn: () => this.repo.findMany(),
    });
  }
}
