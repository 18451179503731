import { KaeplaUserProjectAssignment, WithCollectionId } from '@kaepla/types';
import { limit, query } from 'firebase/firestore';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';

export class UserProjectAssignmentRepository extends FirebaseRepository<
  WithCollectionId<KaeplaUserProjectAssignment>
> {
  entityPath = '/projectAssignment';

  protected findManyQuery() {
    const q = query(this.collection, limit(100));
    return q;
  }
}
