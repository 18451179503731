import { v4 as uuid } from 'uuid';
import { z } from 'zod';

const createAuthUserSchema = z.enum(['KA', 'KB', 'Robert', 'Roland']).array();

export const resellerAssignmentParametersSchema = z.object({
  type: z.literal('Reseller'),
  //
  //
  //
  id: z.literal('RID-Atrigam').or(z.literal('RID-Xotigo')),

  email: z.string().email(),
  assignedBy: z.string().email(),
});
export const customerAssignmentParametersSchema = z.object({
  type: z.literal('Customer'),
  id: z
    .literal('CID-1-Atrigam')
    .or(z.literal('CID-2-Atrigam'))
    .or(z.literal('CID-3-Atrigam'))
    .or(z.literal('CID-1-Xotigo'))
    .or(z.literal('CID-2-Xotigo')),

  email: z.string().email(),
  assignedBy: z.string().email(),
});

export const setupTestEnvironmentSchema = z.discriminatedUnion('command', [
  z.object({
    command: z.literal('restore_from_file'),
    path: z
      .enum([
        'assets/testData/firestoreTestData_stage-1.json',
        'assets/testData/firestoreTestData_stage-2.json',
        'assets/testData/firestoreTestData_stage-3.json',
      ])
      .array(),
  }),
  z.object({
    command: z.literal('reset'),
  }),
  z.object({
    command: z.literal('create_snapshot'),
    name: z.string().default(uuid()),
  }),
  z.object({
    command: z.literal('load_snapshot'),
    name: z.string().default(uuid()),
  }),

  z.object({
    command: z.literal('delete_all_users'),
  }),
  z.object({
    command: z.literal('health'),
  }),
  z.object({
    command: z.literal('get_all_users'),
  }),
  z.object({
    command: z.literal('assign'),
    payload: z.array(customerAssignmentParametersSchema.or(resellerAssignmentParametersSchema)),
  }),
  z.object({
    command: z.literal('create_auth_user'),
    user: createAuthUserSchema,
  }),
  z.object({
    command: z.literal('environment'),
  }),
]);

export type SetupTestEnvironmentUserList = z.input<typeof createAuthUserSchema>;

export const setupTestEnvironmentSchemaList = setupTestEnvironmentSchema.array();

export type SetupTestEnvironmentSchema = z.infer<typeof setupTestEnvironmentSchema>;

type CommandKeys = SetupTestEnvironmentSchema['command'];

export type SetupTestEnvironmentSchemaList = z.infer<typeof setupTestEnvironmentSchemaList>;
export type SetupTestEnvironmentSchemaListResponse = Record<CommandKeys, boolean>;
