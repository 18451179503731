import { z, ZodType } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreResellerAssignmentListPath = '/resellerAssignments';

export type KaeplaFirestoreResellerAssignmentPath =
  `${KaeplaFirestoreResellerAssignmentListPath}/${string}`;

export interface KaeplaResellerAssignment {
  assignedAt: FirestoreTimestamp;
  assignedBy: string;
  canCreateCustomer: boolean;
  complete?: boolean;
  id: string;
  inviteAccessDomain: string;
  inviteEmail: string;
  resellerId: string;
  uid: string;
  updatedAt: FirestoreTimestamp;
}

export const kaeplaResellerAssignmentSchema = z.object({
  assignedAt: firestoreTimestampSchema,
  assignedBy: z.string(),
  canCreateCustomer: z.boolean(),
  complete: z.boolean().optional(),
  id: z.string(),
  inviteAccessDomain: z.string(),
  inviteEmail: z.string(),
  resellerId: z.string(),
  uid: z.string(),
  updatedAt: firestoreTimestampSchema,
}) satisfies ZodType<KaeplaResellerAssignment>;
