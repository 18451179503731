import { Timestamp } from 'firebase/firestore';
import { z, ZodType } from 'zod';

export interface FirestoreTimestamp {
  readonly seconds: number;
  readonly nanoseconds: number;
  toDate: () => Date;
  isEqual: (other: Timestamp) => boolean;
  toJSON: () => {
    seconds: number;
    nanoseconds: number;
  };
  toMillis: () => number;
  valueOf: () => string;
}

export const firestoreTimestampSchema = z.custom((data) => {
  const isTimestamp = data instanceof Timestamp;
  //
  // !!!
  //
  //  data instanceof Timestamp; is false in backend
  //
  // would should compare with
  // import { Timestamp } from 'firebase-admin/firestore';
  //
  // but we are in the type packages which gets also consumed by the frontend we dont want to import node deps
  //
  if (!isTimestamp) {
    if ('_seconds' in data && '_nanoseconds' in data) {
      return true;
    }
    if ('seconds' in data && 'nanoseconds' in data) {
      // front-end
      return true;
    }
    return false;
  }

  return isTimestamp;
}) satisfies ZodType<FirestoreTimestamp>;
