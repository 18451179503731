import { Box, Paper } from '@mui/material';
import Favicon from 'react-favicon';

import { useBrandingQuery } from '../../../hooks/query/useBrandingQuery.js';
import { SWUpdater } from '../../features/ServiceWorkerUpdater.js';

import { BrandedLogout } from './features/BrandedLogout.js';

export const Logout = () => {
  const { data: branding } = useBrandingQuery();

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#EEEEEE',
      }}
    >
      {branding?.appIconBase64 && <Favicon url={branding?.appIconBase64} />}
      <SWUpdater />
      <Paper
        data-testid="login-BrandedLoginPaper"
        sx={{ padding: 5, minWidth: 350, maxWidth: 400 }}
      >
        {branding?.appName && <BrandedLogout branding={branding} />}
      </Paper>
    </Box>
  );
};
