import { z } from 'zod';

export enum KaeplaEventGroup {
  AI = 'Ai',
  ASSIGNMENTS = 'Assignments',
  SCOPES = 'Scopes',
  PERSPECTIVES = 'Perspectives',
  PROJECTS = 'Projects',
  SIMULATIONS = 'Simulations',
  ALIGNMENTS = 'Alignments',
  RESELLER = 'Reseller',
  CUSTOMER = 'Customer',
  ADMIN = 'Admin',
  IMPORT = 'Import',
  DATA_SNAPSHOT = 'DataSnapshot',
  DATA_EXPORT = 'DataExport',
}

export const kaeplaEventGroupSchema = z.nativeEnum(KaeplaEventGroup);
