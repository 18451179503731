import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //
      // don't retry queries by default when they fail
      //
      retry: false,
      //
      // don't refetch queries by default when the window regains focus.
      //
      // refetchOnWindowFocus: false,
    },
  },
});
