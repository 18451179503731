import { z } from 'zod';

export interface MatrixTimeseriesEntry extends Record<string, unknown> {
  date: string;
  value: number;
  simulationValue?: number;
  snapshotValue?: number;
}

export const matrixTimeseriesEntrySchema = z.object({
  date: z.string(),
  value: z.number(),
  simulationValue: z.number().optional(),
  snapshotValue: z.number().optional(),
}) satisfies z.ZodType<MatrixTimeseriesEntry>;
