import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from './FirestoreTimeStamp.js';

export interface BigQueryError {
  projectId: string;
  simulationId?: string;
  errorMessage: string;
  query: string;
  loggedAt?: FirestoreTimestamp;
}

export const bigQueryErrorSchema = z.object({
  projectId: z.string(),
  simulationId: z.string().optional(),
  errorMessage: z.string(),
  query: z.string(),
  loggedAt: firestoreTimestampSchema.optional(),
}) satisfies z.ZodType<BigQueryError>;
