import { z } from 'zod';

import {
  KaeplaDataGridRequestParameters,
  kaeplaDataGridRequestParametersSchema,
} from './KaeplaDataGridRequestParameters';

export interface KaeplaValidationParameters {
  projectId: string;
  scopePath: string[];
  filterSql?: string;
  dataGridRequestParameters: KaeplaDataGridRequestParameters;
  searchTerm?: string;
  gapOnly?: boolean;
  info?: string;
}

export const kaeplaValidationParametersSchema = z.object({
  projectId: z.string(),
  scopePath: z.array(z.string()),
  filterSql: z.string().optional(),
  dataGridRequestParameters: kaeplaDataGridRequestParametersSchema,
  searchTerm: z.string().optional(),
  gapOnly: z.boolean().optional(),
  info: z.string().optional(),
}) satisfies z.ZodType<KaeplaValidationParameters>;
