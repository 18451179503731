import { z } from 'zod';

export type Brand<T, K> = T & { __brand: K };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithoutBrand<T extends Brand<Record<string, unknown>, any>> = Omit<T, '__brand'>;

export type WithCollectionId<T = unknown> = T & {
  _id: string;
};

export const nullOrUndefined = z.undefined().or(z.null()).default(null);

// export function withCollectionIdSchema<T extends ZodRawShape>(
//   schema: ZodObject<T> | ZodType<T, z.ZodTypeDef, T>,
// ) {
//   if ('extend' in schema) {
//     return schema.extend({
//       _id: z.string(),
//     });
//   }
//   return schema.and(
//     z.object({
//       _id: z.string(),
//     }),
//   );
// }
