import { KaeplaConnector, KaeplaFirestoreCustomerPath } from '@kaepla/types';
import { limit, query } from 'firebase/firestore';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';

export class ConnectorRepository extends FirebaseRepository<KaeplaConnector> {
  entityPath = `/connectors`;

  constructor({ customerId }: { customerId: string }) {
    const customerPath: KaeplaFirestoreCustomerPath = `/customers/${customerId}`;
    super(customerPath);
  }

  protected findManyQuery() {
    const q = query(this.collection, limit(100));
    return q;
  }
}
