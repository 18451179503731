import { KaeplaBranding } from '@kaepla/types';
import { Grid2 as Grid, Typography } from '@mui/material';

import { Image } from '../../../../primitive/Image/Image.js';
import { documentTitle } from '../../../helpers/documentTitle.js';

export function BrandedLoginHeader({ branding }: { branding: KaeplaBranding | null }) {
  return (
    <Grid container spacing={3} alignItems="center">
      {branding?.appLogoUrl && (
        <Grid size={12} container justifyContent="center">
          <Image
            data-testid="login-BrandedLoginImage"
            width={200}
            alt={documentTitle(branding)}
            src={branding?.appLogoUrl}
          />
        </Grid>
      )}
      {branding?.appName && !branding.appLogoUrl && (
        <Grid size={12}>
          <Typography data-testid="login-BrandedLoginAppName" sx={{ color: '#000' }} variant="h2">
            {branding.appName}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
