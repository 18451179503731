import { z } from 'zod';

import { MatrixJob, matrixJobSchema } from './MatrixJob.js';

export interface MatrixTotalCount {
  totalCount: number;
  job: MatrixJob;
}

export const matrixTotalCountSchema = z.object({
  totalCount: z.number(),
  job: matrixJobSchema,
}) satisfies z.ZodType<MatrixTotalCount>;
