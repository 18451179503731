import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreProjectAssignmentListPath = '/projectAssignments';

export type KaeplaFirestoreProjectAssignmentPath =
  `${KaeplaFirestoreProjectAssignmentListPath}/${string}`;

export interface KaeplaProjectAssignment {
  assignedAt: FirestoreTimestamp;
  assignedBy: string;
  complete?: boolean;
  customerId: string;
  id: string;
  inviteAccessDomain: string;
  inviteEmail: string;
  joinedAt?: FirestoreTimestamp;
  lastVisitedAt?: FirestoreTimestamp;
  projectId: string;
  resellerId: string;
  scopePath: string[];
  scopePathStringified: string;
  uid: string;
  updatedAt: FirestoreTimestamp;
}

export const kaeplaProjectAssignmentSchema = z.object({
  assignedAt: firestoreTimestampSchema,
  assignedBy: z.string(),
  complete: z.boolean().optional(),
  customerId: z.string(),
  id: z.string(),
  inviteAccessDomain: z.string(),
  inviteEmail: z.string(),
  joinedAt: firestoreTimestampSchema.optional(),
  lastVisitedAt: firestoreTimestampSchema.optional(),
  projectId: z.string(),
  resellerId: z.string(),
  scopePath: z.array(z.string()),
  scopePathStringified: z.string(),
  uid: z.string(),
  updatedAt: firestoreTimestampSchema,
}) satisfies z.ZodType<KaeplaProjectAssignment>;
