import { z } from 'zod';

import { customerSchema, KaeplaCustomer } from './Application/KaeplaCustomer.js';
import { MatrixApiError, matrixApiErrorSchema } from './MatrixApiError.js';
import { MatrixCheck, matrixCheckSchema } from './MatrixCheck.js';
import { MatrixData, matrixDataSchema } from './MatrixData.js';
import { MatrixDimensionValues, matrixDimensionValuesSchema } from './MatrixDimensionValues.js';
import { MatrixDimensions, matrixDimensionsSchema } from './MatrixDimensions.js';
import { MatrixOperations, matrixOperationsSchema } from './MatrixOperations.js';
import { MatrixPathDetails, matrixPathDetailsSchema } from './MatrixPathDetails.js';
import {
  MatrixSimulationParameters,
  matrixSimulationParametersSchema,
} from './MatrixSimulationParameters.js';
import { MatrixSummary, matrixSummarySchema } from './MatrixSummary.js';
import { MatrixTimeseries, matrixTimeseriesSchema } from './MatrixTimeseries.js';

export interface MatrixApiResponse {
  projectId: string;
  matrixName: string;
  customer?: KaeplaCustomer;
  response?:
    | MatrixCheck
    | MatrixDimensions
    | MatrixSummary
    | MatrixOperations
    | MatrixData
    | MatrixTimeseries
    | MatrixSimulationParameters
    | MatrixPathDetails
    | MatrixDimensionValues
    | MatrixApiError;
}

export const matrixApiResponseSchema = z.object({
  projectId: z.string(),
  matrixName: z.string(),
  customer: customerSchema.optional(),
  response: z
    .union([
      matrixCheckSchema,
      matrixDimensionsSchema,
      matrixSummarySchema,
      matrixOperationsSchema,
      matrixDataSchema,
      matrixTimeseriesSchema,
      matrixSimulationParametersSchema,
      matrixPathDetailsSchema,
      matrixDimensionValuesSchema,
      matrixApiErrorSchema,
    ])
    .optional(),
}) satisfies z.ZodType<MatrixApiResponse>;
