import { z } from 'zod';

import { KaeplaCustomerAuthenticationMethod } from './KaeplaCustomerAuthenticationMethod';
import {
  customerAuthenticationMethodSchema,
  KaeplaSamlSsoAuthenticationConfig,
} from './KaeplaSamlSsoAuthenticationConfig';

export interface KaeplaCustomerAuthenticationConfig {
  preferredMethod: KaeplaCustomerAuthenticationMethod;
  configuration: KaeplaSamlSsoAuthenticationConfig;
}

export const authenticationConfigSchema = z.object({
  preferredMethod: z.nativeEnum(KaeplaCustomerAuthenticationMethod),
  configuration: customerAuthenticationMethodSchema,
}) satisfies z.ZodType<KaeplaCustomerAuthenticationConfig>;
