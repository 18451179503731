import { z } from 'zod';

import {
  MatrixSimulationParameter,
  matrixSimulationParameterSchema,
} from './MatrixSimulationParameter.js';

export interface MatrixSimulationParameters {
  rows: MatrixSimulationParameter[];
}

export const matrixSimulationParametersSchema = z.object({
  rows: matrixSimulationParameterSchema.array(),
}) satisfies z.ZodType<MatrixSimulationParameters>;
