import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreCustomerAssignmentListPath = '/customerAssignments';

export type KaeplaFirestoreCustomerAssignmentPath =
  `${KaeplaFirestoreCustomerAssignmentListPath}/${string}`;

export interface KaeplaCustomerAssignment {
  assignedAt: FirestoreTimestamp;
  assignedBy: string;
  canCreateProject: boolean;
  complete: boolean;
  customerId: string;
  id: string;
  inviteAccessDomain: string;
  inviteEmail: string;
  lastInviteSentAt?: FirestoreTimestamp;
  joinedAt?: FirestoreTimestamp;
  resellerId: string;
  uid: string;
  updatedAt?: FirestoreTimestamp;
}

export const customerAssignmentSchema = z.object({
  assignedAt: firestoreTimestampSchema,
  assignedBy: z.string(),
  canCreateProject: z.boolean(),
  complete: z.boolean(),
  customerId: z.string(),
  id: z.string(),
  inviteAccessDomain: z.string(),
  inviteEmail: z.string().email(),
  lastInviteSentAt: firestoreTimestampSchema.optional(),
  joinedAt: firestoreTimestampSchema.optional(),
  resellerId: z.string(),
  uid: z.string(),
  //
  // in the old code updatedAt is optional
  //
  updatedAt: firestoreTimestampSchema.optional(),
}) satisfies z.ZodType<KaeplaCustomerAssignment>;
