import { z } from 'zod';

export interface KaeplaSimulationRulesetFilter {
  filterTreeStringified: string;
  filterSql: string;
  rulesCount: number;
}

export const kaeplaSimulationRulesetFilterSchema = z.object({
  filterTreeStringified: z.string(),
  filterSql: z.string(),
  rulesCount: z.number(),
}) satisfies z.ZodType<KaeplaSimulationRulesetFilter>;
