import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

export type KaeplaFirestoreKaeplaAssignmentListPath = '/kaeplaAssignments';

export type KaeplaFirestoreKaeplaAssignmentPath =
  `${KaeplaFirestoreKaeplaAssignmentListPath}/${string}`;

export interface KaeplaAssignment {
  uid: string;
  devTeamMember: boolean;
  canCreateReseller: boolean;
  assignedAt: FirestoreTimestamp;
}

export const kaeplaAssignmentSchema = z.object({
  uid: z.string(),
  devTeamMember: z.boolean(),
  canCreateReseller: z.boolean(),
  assignedAt: firestoreTimestampSchema,
}) satisfies z.ZodType<KaeplaAssignment>;
