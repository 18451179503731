import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import {
  authenticationConfigSchema,
  KaeplaCustomerAuthenticationConfig,
} from './KaeplaCustomerAuthenticationConfig.js';

export type KaeplaFirestoreCustomerListPath = '/customers';
export type KaeplaFirestoreCustomerPath = `${KaeplaFirestoreCustomerListPath}/${string}`;

export interface KaeplaCustomer {
  appName: string;
  authentication?: KaeplaCustomerAuthenticationConfig;
  createdAt: FirestoreTimestamp;
  createdBy: string;
  creationAccessDomain: string;
  customerDomains?: string[];
  dataMatrixInstance?: string;
  iconBase64?: string;
  iconUrl?: string;
  id: string;
  linkUrl?: string;
  logoUrl?: string;
  name: string;
  projectCount: number;
  resellerDomains?: string[];
  resellerId: string;
  updatedAt: FirestoreTimestamp;
}

export const customerSchema = z.object({
  appName: z.string(),
  authentication: authenticationConfigSchema.optional(),
  createdAt: firestoreTimestampSchema,
  createdBy: z.string(),
  creationAccessDomain: z.string(),
  customerDomains: z.array(z.string()).optional(),
  dataMatrixInstance: z.string().optional(),
  iconBase64: z.string().optional(),
  iconUrl: z.string().optional(),
  id: z.string(),
  linkUrl: z.string().optional(),
  logoUrl: z.string().optional(),
  name: z.string(),
  projectCount: z.number(), // .default(0),
  resellerDomains: z.array(z.string()).optional(),
  resellerId: z.string(),
  updatedAt: firestoreTimestampSchema,
}) satisfies z.ZodType<KaeplaCustomer>;
