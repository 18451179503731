import { KaeplaFirestoreUserListPath, KaeplaUser } from '@kaepla/types';
import { limit, query } from 'firebase/firestore';

import { ensure } from '../helpers/ensure';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';

export class UserRepository extends FirebaseRepository<KaeplaUser> {
  entityPath: KaeplaFirestoreUserListPath = `/users`;

  getCurrentUserPath() {
    ensure(this.auth.currentUser, 'User not authenticated');
    return this.findQuery(this.auth.currentUser.uid).path;
  }

  async getCurrentUser() {
    ensure(this.auth.currentUser, 'User not authenticated');
    const user = await this.find(this.auth.currentUser.uid);
    ensure(user, 'User not found');
    return user;
  }

  protected findManyQuery() {
    const q = query(this.collection, limit(100));
    return q;
  }
}
