import {
  FirestoreTimestamp,
  KaeplaFirestoreProjectListPath,
  KaeplaProject,
  kaeplaProjectSchema,
} from '@kaepla/types';
import { limit, query, Timestamp, where } from 'firebase/firestore';

import { ensure } from '../helpers/ensure';

import { FirebaseRepository } from './AbstractClasses/FirebaseRepository';
import { Where, WhereIn } from './base';

export interface ProjectFindManyParameter {
  in?: Pick<WhereIn<KaeplaProject>, 'id'>;
  where?: Pick<Where<KaeplaProject>, 'id'>;
  limit?: number;
}

export type CreateProjectParameters = Omit<
  KaeplaProject,
  'accessDomain' | 'createdAt' | 'createdBy' | 'dataMatrix' | 'matrixUnavailable' | 'ownedBy' | 'id'
>;

export class ProjectRepository extends FirebaseRepository<KaeplaProject> {
  entityPath: KaeplaFirestoreProjectListPath = `/projects`;
  override schema = kaeplaProjectSchema;

  override async delete(id: string) {
    const project = await this.find(id);
    ensure(project, 'Project not found');
    //
    // @rrust
    //
    // /projectAssignment/$(projectId) is not in sync with /databases/$(database)/documents/users/$(request.auth.uid)/projectAssignment/$(projectId)
    //
    // who cleansup /databases/$(database)/documents/users/$(request.auth.uid)/projectAssignment/$(projectId)
    //
    // after delete ?
    //
    return super.delete(id);
  }

  override create(parameter: CreateProjectParameters) {
    const reference = this.reference;
    const uid = this.auth.currentUser?.uid;
    ensure(uid, 'User not authenticated');
    const payload = {
      //
      //
      //
      accessDomain: globalThis.location.hostname,
      createdAt: Timestamp.now() as FirestoreTimestamp,
      createdBy: uid,
      dataMatrix: `MatrixM${reference.id}`,
      matrixUnavailable: true,
      ownedBy: uid,
      //
      ...parameter,
    };
    console.log('Create Project', payload);
    return super.create(payload, reference);
  }

  protected findManyQuery(parameter?: ProjectFindManyParameter) {
    let q = query(this.collection, limit(parameter?.limit ?? 100));

    if (parameter?.where?.id) {
      q = query(q, where('id', '==', parameter.where.id));
    } else if (parameter?.in?.id) {
      q = query(q, where('id', 'in', parameter.in.id));
    }
    return q;
  }
}
