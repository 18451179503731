import { z } from 'zod';

import { BigQueryError, bigQueryErrorSchema } from './BigQueryError.js';

export interface MatrixApiError {
  bigQueryError: BigQueryError;
}

export const matrixApiErrorSchema = z.object({
  bigQueryError: bigQueryErrorSchema,
}) satisfies z.ZodType<MatrixApiError>;
