import { KaeplaBranding, KaeplaCustomerAuthenticationMethod } from '@kaepla/types';
import { Box } from '@mui/material';

import { SamlSsoAuthenticationFlow } from './AuthenticationFlows/SamlSsoAuthenticationFlow.js';

export function AuthenticationFlow({ branding }: { branding: KaeplaBranding | null }) {
  switch (branding?.authenticationConfiguration?.preferredMethod) {
    case KaeplaCustomerAuthenticationMethod.SAML_SSO: {
      return <SamlSsoAuthenticationFlow branding={branding} />;
    }
    default: {
      return <Box>Unknown authentication method</Box>;
    }
  }
}
