import { queryOptions } from '@tanstack/react-query';

import { queryClient } from '../config/client';
import { BrandingRepository } from '../repository/Branding.repository';

export class BrandingService {
  repo = new BrandingRepository();
  find(hostname: string) {
    return queryOptions({
      queryKey: [this.repo.path],
      queryFn: () => this.repo.find(hostname),
      //
      // don't reload branding information when the window regains focus
      //
      refetchOnWindowFocus: false,
    });
  }

  cache() {
    return queryClient.getQueryData([this.repo.path]);
  }
}
