import { z } from 'zod';

import { dimensionSchema, KaeplaDimension } from './Application/KaeplaDimension.js';
import { MatrixJob, matrixJobSchema } from './MatrixJob.js';

export interface MatrixDimensions {
  dimensions: KaeplaDimension[];
  job?: MatrixJob;
}

export const matrixDimensionsSchema = z.object({
  dimensions: dimensionSchema.array(),
  job: matrixJobSchema.optional(),
}) satisfies z.ZodType<MatrixDimensions>;
