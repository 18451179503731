import { z } from 'zod';

import { KaeplaConnector, kaeplaConnectorSchema } from './KaeplaConnector.js';
import { KaeplaProject, kaeplaProjectSchema } from './KaeplaProject.js';

export interface KaeplaProjectClone {
  project: KaeplaProject;
  customerName?: string;
  customerIcon?: string;
  connector?: KaeplaConnector;
}

export const kaeplaProjectCloneSchema = z.object({
  project: kaeplaProjectSchema,
  customerName: z.string().optional(),
  customerIcon: z.string().optional(),
  connector: kaeplaConnectorSchema.optional(),
}) satisfies z.ZodType<KaeplaProjectClone>;
