import { z } from 'zod';

import { MatrixTotalCount, matrixTotalCountSchema } from './MatrixTotalCount.js';

export interface MatrixCheck {
  exists: boolean;
  totalCount?: MatrixTotalCount;
  error?: string;
}

export const matrixCheckSchema = z.object({
  exists: z.boolean(),
  totalCount: matrixTotalCountSchema.optional(),
  error: z.string().optional(),
}) satisfies z.ZodType<MatrixCheck>;
