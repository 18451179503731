import { z } from 'zod';

// Base
interface MatrixSimulationParameterBase {
  id?: string; // will be set by SQL
  scenario: string;
  simulationId: string;
  scopePathStringified: string;
  rulesetId: string;
  dimension: string;
}

const matrixSimulationParameterBaseSchema = z.object({
  id: z.string().optional(),
  scenario: z.string(),
  simulationId: z.string(),
  scopePathStringified: z.string(),
  rulesetId: z.string(),
  dimension: z.string(),
}) satisfies z.ZodType<MatrixSimulationParameterBase>;

// MatrixSimulationParameterNumeric

export interface MatrixSimulationParameterNumeric extends MatrixSimulationParameterBase {
  parameterType: 'numeric';
  aggregation: string;
  timeSlice: string;
  dateFrom: string;
  dateUntil: string;
  key: string;
  originalValue?: number;
  percentage?: number;
  absoluteValue?: number;
  currency?: string;
  decimalPositions?: number;
}

export const matrixSimulationParameterNumericSchema = matrixSimulationParameterBaseSchema.merge(
  z.object({
    parameterType: z.literal('numeric'),
    aggregation: z.string(),
    timeSlice: z.string(),
    dateFrom: z.string(),
    dateUntil: z.string(),
    key: z.string(),
    originalValue: z.number().optional(),
    percentage: z.number().optional(),
    absoluteValue: z.number().optional(),
    currency: z.string().optional(),
    decimalPositions: z.number().optional(),
  }),
) satisfies z.ZodType<MatrixSimulationParameterNumeric>;

// MatrixSimulationParameterText

export interface MatrixSimulationParameterText extends MatrixSimulationParameterBase {
  parameterType: 'text';
  key: string;
  originalTextValue: string;
  simulatedTextValue: string;
}

export const matrixSimulationParameterTextSchema = matrixSimulationParameterBaseSchema.merge(
  z.object({
    parameterType: z.literal('text'),
    key: z.string(),
    originalTextValue: z.string(),
    simulatedTextValue: z.string(),
  }),
) satisfies z.ZodType<MatrixSimulationParameterText>;

//

export type MatrixSimulationParameter =
  | MatrixSimulationParameterNumeric
  | MatrixSimulationParameterText;

export const matrixSimulationParameterSchema = z.union([
  matrixSimulationParameterNumericSchema,
  matrixSimulationParameterTextSchema,
]) satisfies z.ZodType<MatrixSimulationParameter>;
