/* eslint-disable max-classes-per-file */
import { brandingSchema, KaeplaBranding, KaeplaBrandingRequest } from '@kaepla/types';

import { logger } from '../Frontend/helpers/logger';

import { HttpsCallableRepository } from './AbstractClasses/HttpsCallable.repository';

export class BrandingError extends Error {
  //
  // this should be caught by the error boundary and render connect failed message
  //
  constructor(message: string) {
    super(message);
    this.name = 'BrandingError';
  }
}

export class BrandingRepository extends HttpsCallableRepository<
  KaeplaBrandingRequest,
  KaeplaBranding
> {
  path = 'getKaeplaBranding';
  schemaResponse = brandingSchema;
  // brandingRequestSchema

  async find(hostname: string): Promise<KaeplaBranding | null> {
    try {
      //
      // should be fixed in the branding endoint
      //
      // when no hostname is found it returns {} instead of 404 or null
      //
      const result = await this.fetch({
        hostname,
      });
      const parsed = brandingSchema.nullable().parse(result);
      // await this.client.invalidateQueries({ queryKey: [this.path, hostname] });
      return parsed;
    } catch (error) {
      logger.error('Branding repository', error);
      throw new BrandingError('Branding not found');
    }
  }
}
