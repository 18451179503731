import { z, ZodType } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import { KaeplaFirestoreUserPath } from './KaeplaUser.js';

export type KaeplaFirestoreUserProjectAssignmentListPath =
  `${KaeplaFirestoreUserPath}/projectAssignment`;

export type KaeplaFirestoreUserProjectAssignmentPath =
  `${KaeplaFirestoreUserProjectAssignmentListPath}/${string}`;

export interface KaeplaUserProjectAssignment {
  lastAssignedBy: string;
  lastAssignedAt: FirestoreTimestamp;
}

export const userProjectAssignmentSchema = z.object({
  lastAssignedBy: z.string(),
  lastAssignedAt: firestoreTimestampSchema,
}) satisfies ZodType<KaeplaUserProjectAssignment>;
