import { z } from 'zod';

import {
  KaeplaSimulationRulesetFilter,
  kaeplaSimulationRulesetFilterSchema,
} from './KaeplaSimulationRulesetFilter.js';

export interface KaeplaSimulationRuleset {
  id: string;
  position: number;
  simulationId: string;
  filter?: KaeplaSimulationRulesetFilter;
}

export const kaeplaSimulationRulesetSchema = z.object({
  id: z.string(),
  position: z.number(),
  simulationId: z.string(),
  filter: kaeplaSimulationRulesetFilterSchema.optional(),
}) satisfies z.ZodType<KaeplaSimulationRuleset>;
