import { z } from 'zod';

import {
  KaeplaDimensionDetails,
  kaeplaDimensionDetailsSchema,
} from './Application/KaeplaDimensionDetails.js';
import { MatrixDimensions, matrixDimensionsSchema } from './MatrixDimensions.js';
import { MatrixSimulationYears, matrixSimulationYearsSchema } from './MatrixSimulationYears.js';
import { MatrixTotalCount, matrixTotalCountSchema } from './MatrixTotalCount.js';

export interface MatrixSummary {
  totalCount?: MatrixTotalCount;
  simulationYears?: MatrixSimulationYears;
  dimensions?: MatrixDimensions;
  details?: KaeplaDimensionDetails[];
  error?: string;
}

export const matrixSummarySchema = z.object({
  totalCount: matrixTotalCountSchema.optional(),
  simulationYears: matrixSimulationYearsSchema.optional(),
  dimensions: matrixDimensionsSchema.optional(),
  details: kaeplaDimensionDetailsSchema.array().optional(),
  error: z.string().optional(),
}) satisfies z.ZodType<MatrixSummary>;
