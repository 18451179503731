import { z } from 'zod';

import {
  KaeplaSimulationParameter,
  kaeplaSimulationParameterSchema,
} from './KaeplaSimulationParameter.js';
import {
  KaeplaSimulationRuleset,
  kaeplaSimulationRulesetSchema,
} from './KaeplaSimulationRuleset.js';

export type KaeplaSimulationRulesetWithParameters = KaeplaSimulationRuleset & {
  parameters: KaeplaSimulationParameter[];
};

export const kaeplaSimulationRulesetWithParametersSchema = kaeplaSimulationRulesetSchema.extend({
  parameters: z.array(kaeplaSimulationParameterSchema),
}) satisfies z.ZodType<KaeplaSimulationRulesetWithParameters>;
