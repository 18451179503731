import { z } from 'zod';

import { FirestoreTimestamp, firestoreTimestampSchema } from '../FirestoreTimeStamp.js';

import { KaeplaFirestoreUserPath } from './KaeplaUser.js';

export type KaeplaFirestoreUserResellerAssignmentListPath =
  `${KaeplaFirestoreUserPath}/resellerAssignment`;

export type KaeplaFirestoreUserResellerAssignmentPath =
  `${KaeplaFirestoreUserResellerAssignmentListPath}/${string}`;

export interface KaeplaUserResellerAssignment {
  assignedBy: string;
  assignedAt: FirestoreTimestamp;
  canCreateCustomer: boolean;
}

export const userResellerAssignmentSchema = z.object({
  assignedBy: z.string(),
  assignedAt: firestoreTimestampSchema,
  canCreateCustomer: z.boolean(),
}) satisfies z.ZodType<KaeplaUserResellerAssignment>;
