import { KaeplaCustomerAssignment, KaeplaFirestoreCustomerAssignmentListPath } from '@kaepla/types';
import { limit, query, where } from 'firebase/firestore';
import { is, isEmpty } from 'rambda';

import { ensure } from '../../helpers/ensure';
import { FirebaseRepository } from '../AbstractClasses/FirebaseRepository';
import { Where } from '../base';

export interface CustomerAssignmentsFindManyParameter {
  currentUserId?: string;
  where: Pick<Where<KaeplaCustomerAssignment>, 'id' | 'complete' | 'customerId' | 'resellerId'>;
  limit?: number;
}

export class CustomerAssignmentsRepository extends FirebaseRepository<KaeplaCustomerAssignment> {
  entityPath: KaeplaFirestoreCustomerAssignmentListPath = '/customerAssignments';

  protected findManyQuery(parameter?: Partial<CustomerAssignmentsFindManyParameter>) {
    let q = query(this.collection, limit(parameter?.limit ?? 100));

    if (!parameter) return q;
    if (isEmpty(parameter)) return q;

    if (is(String, parameter.currentUserId)) {
      ensure(this.auth.currentUser?.uid, 'User not authenticated');
      ensure(this.auth.currentUser?.uid === parameter.currentUserId, 'User not authenticated');
      q = query(q, where('uid', '==', parameter.currentUserId));
    }

    // WHERE COMPLETE
    if (is(Array, parameter.where?.complete)) {
      q = query(q, where('complete', 'in', parameter.where.complete));
    }
    if (is(Boolean, parameter.where?.complete)) {
      q = query(q, where('complete', '==', parameter.where.complete));
    }
    //
    // WHERE ID
    //
    if (is(Array, parameter.where?.id)) {
      q = query(q, where('id', 'in', parameter.where.id));
    }
    if (is(Boolean, parameter.where?.id)) {
      q = query(q, where('id', '==', parameter.where.id));
    }

    // WHERE CUSTOMERID
    if (is(Array, parameter.where?.customerId)) {
      q = query(q, where('customerId', 'in', parameter.where.customerId));
    }
    if (is(Boolean, parameter.where?.customerId)) {
      q = query(q, where('customerId', '==', parameter.where.customerId));
    }

    // WHERE RESELLERID
    if (is(Array, parameter.where?.resellerId)) {
      q = query(q, where('resellerId', 'in', parameter.where.resellerId));
    }
    if (is(Boolean, parameter.where?.resellerId)) {
      q = query(q, where('resellerId', '==', parameter.where.resellerId));
    }

    return q;
  }
}
