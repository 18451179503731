import { z } from 'zod';

import { MatrixJobType } from './MatrixJobType.js';

export interface MatrixJob {
  project?: string;
  dataMatrixInstance?: string;
  simulation?: string;
  type?: MatrixJobType;
  ms: number;
  bytes: number;
  cached: boolean;
  error?: string;
}

export const matrixJobSchema = z.object({
  project: z.string().optional(),
  dataMatrixInstance: z.string().optional(),
  simulation: z.string().optional(),
  type: z.nativeEnum(MatrixJobType).optional(),
  ms: z.number(),
  bytes: z.number(),
  cached: z.boolean(),
  error: z.string().optional(),
}) satisfies z.ZodType<MatrixJob>;
